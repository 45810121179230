import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, FormControl, Link, NativeSelect, Typography, } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import CompanyImg from "../../../img/scanner/company-img.png";
import logoPath from "../../../img/scanner/logo.svg";
export default function Footer() {
    const { t, i18n } = useTranslation();
    const handleChangeLng = (event) => {
        const lng = event.target.value;
        i18n.changeLanguage(lng);
        localStorage.setItem("lng", lng);
    };
    const location = useLocation();
    const isDev = new URLSearchParams(location.search).get("dev") === "true";
    return (_jsxs(Box, { sx: { marginTop: "8rem", px: 2, py: 2, backgroundColor: "#F9FAFB" }, children: [_jsxs(Stack, { sx: {
                    margin: "5% 0",
                    flexDirection: { xs: "column", sm: "row" },
                    justifyContent: "space-between",
                }, children: [_jsxs(Box, { sx: { width: "50%" }, children: [_jsx(Typography, { color: "#000", children: t("about-us") }), _jsx(Typography, { variant: "body2", children: t("about-us-desc") }), _jsx(Box, { component: "img", sx: {
                                    height: "auto",
                                    width: "100%",
                                    maxWidth: "230px",
                                    mt: "20px",
                                }, src: CompanyImg })] }), _jsxs(Box, { children: [_jsx(Typography, { color: "#000", children: t("privacy") }), _jsx(Link, { href: "/privacy", target: "_blank", underline: "none", children: _jsx(Typography, { variant: "body2", children: t("privacy-policy") }) }), _jsx(Link, { href: "/terms", target: "_blank", underline: "none", children: _jsx(Typography, { variant: "body2", children: t("terms-conditions") }) }), _jsx(Link, { href: "/cookies", target: "_blank", underline: "none", children: _jsx(Typography, { variant: "body2", children: t("cookies") }) }), _jsx(Link, { href: "/refunds", target: "_blank", underline: "none", children: _jsx(Typography, { variant: "body2", children: t("refund-policy") }) })] }), _jsxs(Box, { children: [_jsx(Typography, { color: "#000", children: t("other") }), _jsx(Link, { href: "/prices", target: "_blank", underline: "none", children: _jsx(Typography, { variant: "body2", children: t("prices") }) }), _jsx(Link, { href: "/unsubscribe", target: "_blank", underline: "none", children: _jsx(Typography, { variant: "body2", children: t("unsubscribe") }) }), _jsx(Link, { href: "/contact", target: "_blank", underline: "none", children: _jsx(Typography, { variant: "body2", children: t("contact-us") }) })] })] }), _jsx(Divider, {}), _jsxs(Stack, { direction: "row", alignItems: "center", justifyContent: "space-between", children: [_jsxs(Stack, { direction: "row", alignItems: "center", children: [_jsx(Link, { href: "/", children: _jsx(Box, { component: "img", sx: { width: "60px" }, alt: "Topqr Logo", src: logoPath }) }), _jsx(Link, { href: "/", sx: { textDecoration: "none" }, children: _jsx(Typography, { mb: 0, mt: 0, sx: {
                                        lineHeight: 1.2,
                                        display: {
                                            xs: "none",
                                            sm: "block",
                                            fontSize: "30px",
                                            fontWeight: "500",
                                            color: "#000",
                                        },
                                    }, fontWeight: "fontWeightMedium", children: "Topqr.net" }) })] }), _jsx(FormControl, { children: _jsxs(NativeSelect, { value: i18n.language, onChange: handleChangeLng, children: [_jsx("option", { value: "en", children: "English" }), _jsx("option", { value: "fr", children: "French" })] }) })] })] }));
}
