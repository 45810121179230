import { jsx as _jsx } from "react/jsx-runtime";
import { useMutation } from "@tanstack/react-query";
import axios, { isAxiosError } from "axios";
import { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
const DEFAULT_STATE = {
    qrType: "website",
    resetQRCodeLayout: () => { },
    setQRType: () => { },
    setQRStyle: () => { },
    setCreatingQRCodeState: () => { },
    setContentForm: () => { },
    setEmailForm: () => { },
    createQRCode: () => { },
    handleNextClick: () => { },
    qrStyle: {
        styleContent: "default",
        styleOuterEyes: "default",
        styleInnerEyes: "default",
        contentColor: "#000000",
        outerColor: "#000000",
        iconUrl: "",
        language: "en",
    },
    currentForm: null,
    currentEmailForm: null,
    creatingQRCode: {
        status: "idle",
    },
};
const CreateQRContext = createContext(DEFAULT_STATE);
export const CreateQRPProvider = ({ children, }) => {
    const { session } = useAuth();
    const [qrType, setQRType] = useState("website");
    const [qrStyle, setQRStyle] = useState(DEFAULT_STATE.qrStyle);
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const [currentForm, setContentForm] = useState(DEFAULT_STATE.currentForm);
    const [creatingQRCode, setCreatingQRCodeState] = useState(DEFAULT_STATE.creatingQRCode);
    const [currentEmailForm, setEmailForm] = useState(DEFAULT_STATE.currentEmailForm);
    const handleSuccess = () => {
        setCreatingQRCodeState({ status: "success" });
        if (session.email)
            navigate("/dashboard");
    };
    const handleError = (error) => {
        var _a;
        setCreatingQRCodeState({
            status: "error",
        });
        if (isAxiosError(error)) {
            currentEmailForm === null || currentEmailForm === void 0 ? void 0 : currentEmailForm.setError("email", {
                type: "custom",
                message: (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.error,
            });
        }
        else {
            currentEmailForm === null || currentEmailForm === void 0 ? void 0 : currentEmailForm.setError("email", {
                type: "custom",
                message: "There has been an issue with this request. Please try again later.",
            });
        }
    };
    const mutation = useMutation((qr) => {
        setCreatingQRCodeState({ status: "pending" });
        return axios.post("/api/code", JSON.stringify(qr), {
            headers: { "Content-Type": "application/json" },
        });
    }, {
        onSuccess: handleSuccess,
        onError: handleError,
    });
    const resetQRCodeLayout = () => {
        setQRType("website");
        setQRStyle(DEFAULT_STATE.qrStyle);
        setCreatingQRCodeState(DEFAULT_STATE.creatingQRCode);
        setContentForm(null);
        setEmailForm(null);
    };
    const executeQRCodeMutation = (e, content) => {
        return mutation.mutate({
            loggedIn: Boolean(session.email),
            email: e,
            type: qrType,
            content,
            ...qrStyle,
            language: i18n.language,
            terms: true,
        });
    };
    const createQRCode = async () => {
        if (!currentForm) {
            throw new Error("Invalid form");
        }
        const isValid = await currentForm.trigger();
        const e = session.email || localStorage.getItem("onboardingEmail");
        const content = currentForm.getValues();
        if (!isValid || !e) {
            throw new Error("Invalid form");
        }
        executeQRCodeMutation(e, content);
    };
    const handleNextClick = async () => {
        const isValid = await (currentForm === null || currentForm === void 0 ? void 0 : currentForm.trigger());
        if (!isValid) {
            return;
        }
        if (session === null || session === void 0 ? void 0 : session.email) {
            // logged in (email exists)
            createQRCode();
        }
        else {
            setCreatingQRCodeState({ status: "email-cta" });
        }
    };
    return (_jsx(CreateQRContext.Provider, { value: {
            qrType,
            resetQRCodeLayout,
            setQRType: (type) => {
                setContentForm(null);
                setQRType(type);
            },
            setQRStyle: (s) => {
                setQRStyle({ ...qrStyle, ...s });
            },
            qrStyle,
            setContentForm,
            setEmailForm,
            currentForm,
            currentEmailForm,
            creatingQRCode,
            setCreatingQRCodeState,
            createQRCode,
            handleNextClick,
        }, children: children }));
};
export const useCreateQR = () => {
    const context = useContext(CreateQRContext);
    if (context === undefined) {
        throw new Error("useCreateQR must be used within a CreateQRProvider");
    }
    return context;
};
