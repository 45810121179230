import { jsx as _jsx } from "react/jsx-runtime";
import { ArrowForward } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ScannerButton from "../../components/common/ScannerButton";
import { useCreateQR } from "../../context/CreateQRProvider";
export default function NextButton({ sx = {} }) {
    const { creatingQRCode, handleNextClick } = useCreateQR();
    const { t } = useTranslation();
    return (_jsx(ScannerButton, { onClick: handleNextClick, endIcon: _jsx(ArrowForward, {}), disabled: creatingQRCode.status === "pending", sx: {
            fontSize: {
                xs: 16,
                sm: 20,
                md: 24,
            },
            flex: 1,
            ...sx,
        }, children: t("createqr-next") }));
}
