import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { zodResolver } from "@hookform/resolvers/zod";
import { Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useCreateQR } from "../../context/CreateQRProvider";
import UploadBackgroundComponent from "../common/UploadBackgroundComponent";
import UploadComponent from "../common/UploadComponent";
const useImageForm = () => {
    const { currentForm, qrType } = useCreateQR();
    return useForm({
        resolver: zodResolver(z.object({
            "file-url": z.string().url(),
        })),
        defaultValues: currentForm && qrType === "image"
            ? currentForm.getValues()
            : {
                file: "",
                "file-url": "",
                "file-name": "",
            },
    });
};
const Image = () => {
    const form = useImageForm();
    const { setContentForm } = useCreateQR();
    const { t } = useTranslation();
    const mutation = useMutation({
        mutationFn: (data) => {
            return axios.post("/api/code/file", data);
        },
        onSuccess: (data) => {
            var _a;
            form.setValue("file", "");
            form.setValue("file-url", (_a = data.data) === null || _a === void 0 ? void 0 : _a.fileS3Url);
        },
    });
    useEffect(() => {
        setContentForm(form);
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "body1", mb: 2, children: t("create-qr-image-description") }), _jsx(UploadBackgroundComponent, { desc: t("createqr-video-max-upload"), children: _jsx(UploadComponent, { type: "image", accept: "image/*", label: "createqr-image-upload", form: form, mutation: mutation }) })] }));
};
export default Image;
