import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Link, Typography } from "@mui/material";
export default function PageNotFound() {
    return (_jsxs(Box, { sx: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            minHeight: "100vh",
            width: "100%",
            backgroundColor: "primary.main",
        }, children: [_jsx(Typography, { variant: "h1", style: { color: "white" }, children: "404" }), _jsx(Typography, { variant: "h6", style: { color: "white", marginBottom: "3rem" }, children: "The page you\u2019re looking for doesn\u2019t exist." }), _jsx(Button, { component: Link, href: "/", variant: "contained", children: "Back Home" })] }));
}
