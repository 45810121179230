import { jsx as _jsx } from "react/jsx-runtime";
import { Box, InputBase, styled } from "@mui/material";
import { Controller } from "react-hook-form";
const InputElement = styled(InputBase) `
  ${({ theme }) => `
    background-color: white;
    border-radius: 8px;
    border: 1px solid #DDE1E6;
    padding: .7rem 1rem .5rem;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
      border: 1px solid #DDE1E6;
    }
    &.Mui-focused:not(.Mui-error) {
      border: 1px solid #6a87ae;
      box-shadow: 0px 1px 1px #00000008, 0px 3px 6px #00000005,0 0 0 3px hsla(210, 96%, 45%, 25%), 0 1px 1px 0 rgba(0, 0, 0, 0.08);
    }
    &.Mui-error {
      border-color: ${theme.palette.error.main};
      box-shadow:  0 0 0 1px ${theme.palette.error.main};
      color: ${theme.palette.error.main};
      input::placeholder {
        opacity: 1;
        color: ${theme.palette.error.main};
      }
    }
  `}
`;
export function TextInput({ control, name, label, formState, onChangeCb, ...rest }) {
    const errors = formState.errors;
    const isDirty = formState.dirtyFields[name];
    const error = errors[name];
    return (_jsx(Controller, { control: control, name: name, render: ({ field }) => (_jsx(Box, { children: _jsx(InputElement, { ...field, ...rest, onChange: (e) => {
                    field.onChange(e);
                    onChangeCb === null || onChangeCb === void 0 ? void 0 : onChangeCb(e);
                }, isdirty: isDirty, placeholder: label, error: !!error }) })) }));
}
