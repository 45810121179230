import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography } from "@mui/material";
function UploadBackgroundComponent({ children, desc, }) {
    return (_jsxs(Stack, { sx: {
            backgroundColor: "white",
            borderStyle: "dashed",
            borderColor: "grey.500",
            borderRadius: "10px",
            height: "11rem",
            alignItems: "center",
            justifyContent: "center",
            rowGap: "1rem",
        }, children: [children, _jsx(Typography, { sx: {
                    color: "#000",
                }, variant: "subtitle2", fontWeight: "medium", children: desc })] }));
}
export default UploadBackgroundComponent;
