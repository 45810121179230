import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import Header from "../components/Header";
function RefundPolicy() {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx(Header, {}), _jsxs(Box, { sx: { padding: "0 5%", margin: "5% auto", maxWidth: "1000px" }, children: [_jsx(Typography, { variant: "h2", mb: 1, children: t("refund-definitions-title") }), _jsxs(Typography, { variant: "body2", children: [t("refund-definitions-desc"), _jsxs("ul", { children: [_jsx("li", { children: t("refund-definitions-list-one") }), _jsx("li", { children: t("refund-definitions-list-two") }), _jsx("li", { children: t("refund-definitions-list-three") }), _jsx("li", { children: t("refund-definitions-list-four") }), _jsx("li", { children: t("refund-definitions-list-five") }), _jsx("li", { children: t("refund-definitions-list-six") })] })] }), _jsx(Typography, { variant: "h2", mt: 4, mb: 1, children: t("refund-returns-title") }), _jsx(Typography, { variant: "body2", children: t("refund-returns-desc") }), _jsx(Typography, { variant: "h2", mt: 4, mb: 1, children: t("refund-refunds-title") }), _jsx(Typography, { variant: "body2", children: t("refund-refunds-desc") }), _jsx(Typography, { variant: "h2", mt: 4, mb: 1, children: t("refund-cancelling-title") }), _jsx(Typography, { variant: "body2", children: t("refund-cancelling-desc") }), _jsx(Typography, { variant: "h2", mt: 4, mb: 1, children: t("refund-consent-title") }), _jsx(Typography, { variant: "body2", children: t("refund-consent-desc") }), _jsx(Typography, { variant: "h2", mt: 4, children: t("refund-changes-title") }), _jsx(Typography, { variant: "body2", children: t("refund-changes-desc") }), _jsx(Typography, { variant: "h2", mt: 4, mb: 1, children: t("refund-contact-title") }), _jsxs(Typography, { variant: "body2", children: [t("refund-contact-desc"), _jsxs("ul", { children: [_jsx("li", { children: t("refund-contact-list-one") }), _jsx("li", { children: t("refund-contact-list-two") })] })] })] }), _jsx(Footer, {})] }));
}
export default RefundPolicy;
