import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Box, Button, Dialog, DialogActions, DialogTitle, Stack, Typography, styled, useMediaQuery, useTheme, } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import fileDownload from "js-file-download";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ScannerBackdrop from "../../components/common/ScannerBackdrop";
import ScannerButton from "../../components/common/ScannerButton";
import { getToken } from "../../context/AuthProvider";
const StyledStack = styled(Stack)(({ theme }) => ({
    borderStyle: "solid",
    borderRadius: "10px",
    borderColor: "lightgray",
    backgroundColor: theme.palette.background.default,
    margin: "10px auto",
    padding: "10px",
}));
const Account = () => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const mutation = useMutation(async () => {
        return await axios.post("/api/dashboard-unsubscribe", null, {
            withCredentials: true,
            headers: { Authorization: "Bearer " + getToken() },
        });
    });
    const handleConfirmUnsubscribe = () => {
        mutation.mutate();
        setOpen(false);
    };
    const { isLoading, error, data } = useQuery(["accountData"], () => axios
        .get("/api/dashboard-account", {
        withCredentials: true,
        headers: { Authorization: "Bearer " + getToken() },
    })
        .then((res) => res.data));
    const handleDownload = (url) => {
        axios
            .get(url, {
            responseType: "blob",
            withCredentials: true,
            headers: { Authorization: "Bearer " + getToken() },
        })
            .then((res) => {
            const contentDisposition = res.headers["content-disposition"];
            let filename = "";
            if (contentDisposition) {
                const match = contentDisposition.match(/filename\s*=\s*"(.+)"/i);
                if (match) {
                    filename = match[1];
                }
            }
            fileDownload(res.data, filename);
        });
    };
    const resetPassMutation = useMutation({
        mutationFn: async () => {
            return await axios.post("/api/request-password", null, {
                headers: { Authorization: "Bearer " + getToken() },
            });
        },
    });
    const handleResetPassword = () => {
        resetPassMutation.mutate();
    };
    if (error instanceof Error)
        return (_jsx(Box, { children: _jsxs(Typography, { children: ["An error has occurred ", error.message] }) }));
    return (_jsxs(Box, { sx: { padding: "1rem" }, children: [_jsx(ScannerBackdrop, { open: isLoading }), _jsx(Typography, { children: t("dashboard-account-title") + " " + (data === null || data === void 0 ? void 0 : data.createdAt) }), _jsxs(StyledStack, { sx: { flexDirection: { xs: "column", sm: "row" } }, children: [_jsxs(Stack, { sx: {
                            gap: { sm: "2rem" },
                            width: { xs: "100%", sm: "50%" },
                        }, children: [_jsx(Typography, { children: t("dashboard-account-reset-title") }), _jsx(Box, { sx: { textAlign: "left" }, children: _jsx(ScannerButton, { onClick: handleResetPassword, disabled: resetPassMutation.isLoading, sx: {
                                        backgroundColor: resetPassMutation.isSuccess
                                            ? "success.main"
                                            : "primary",
                                    }, children: resetPassMutation.isSuccess
                                        ? t("dashboard-account-reset-success")
                                        : t("dashboard-account-reset-button") }) })] }), _jsxs(Stack, { sx: {
                            gap: { sm: "2rem" },
                        }, children: [_jsx(Typography, { children: t("dashboard-account-email-title") }), _jsx(Typography, { children: data === null || data === void 0 ? void 0 : data.email })] })] }), _jsxs(StyledStack, { children: [_jsx(Typography, { variant: "h4", children: t("dashboard-account-your-invoice") }), _jsx(Typography, { children: `${t("dashboard-account-associated-cc")} ${data === null || data === void 0 ? void 0 : data.cardPan}` }), _jsx(Stack, { sx: { flexDirection: "row" }, children: _jsxs(Typography, { children: [`${t("dashboard-account-next-billing")} `, (data === null || data === void 0 ? void 0 : data.nextRebill) ? data.nextRebill : ""] }) }), data === null || data === void 0 ? void 0 : data.transactions.map((trans) => {
                        return (_jsxs(Stack, { sx: { flexDirection: "row", columnGap: "0.4rem" }, children: [_jsx(Typography, { sx: {
                                        width: "50%",
                                        fontSize: { xs: "0.8rem", md: "1.1rem" },
                                    }, children: `${t("dashboard-account-payment-on")}
                ${trans.createdAt}
                ${t("dashboard-account-payment-of")}
                ${i18n.language === "en"
                                        ? "€" + trans.amount
                                        : trans.amount + "€"}` }), _jsx(Stack, { sx: {
                                        width: "50%",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }, children: _jsx(ScannerButton, { sx: {
                                            margin: "0 0",
                                            padding: { xs: "5px 15px", sm: "10px 30px" },
                                            fontSize: { xs: ".6rem", sm: "0.7rem", md: "1rem" },
                                        }, onClick: () => handleDownload(trans.invoiceUrl), children: t("dashboard-account-download-invoice") }) })] }, trans.id));
                    })] }), _jsxs(StyledStack, { sx: { borderColor: "error.light" }, children: [_jsx(Typography, { variant: "h4", children: t("dashboard-account-account-status") }), _jsx(Button, { onClick: handleClickOpen, disabled: (data === null || data === void 0 ? void 0 : data.status) !== 1, children: t("dashboard-account-unsubscribe") })] }), _jsxs(Dialog, { fullScreen: fullScreen, open: open, onClose: handleClose, "aria-labelledby": "responsive-dialog-title", children: [_jsx(DialogTitle, { id: "responsive-dialog-title", children: t("dashboard-account-unsubscribe-confirmation") }), _jsxs(DialogActions, { children: [_jsx(Button, { autoFocus: true, onClick: handleClose, children: t("dashboard-account-unsubscribe-cancel") }), _jsx(Button, { onClick: handleConfirmUnsubscribe, autoFocus: true, children: t("dashboard-account-unsubscribe-confirm") })] })] })] }));
};
export default Account;
