import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, TextField, Typography } from "@mui/material";
import { isEmpty } from "lodash-es";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useCreateQR } from "../../context/CreateQRProvider";
import { URLRegexValidator, focusInput } from "../../helpers";
const Application = () => {
    const { t } = useTranslation();
    const { setContentForm, currentForm, qrType } = useCreateQR();
    const form = useForm({
        defaultValues: currentForm &&
            !isEmpty(currentForm.getValues()) &&
            qrType === "application"
            ? currentForm.getValues()
            : {
                "app-name": "",
                "app-ios": "",
                "app-android": "",
            },
    });
    const { clearErrors } = form;
    useEffect(() => {
        focusInput("app-name");
        setContentForm(form);
    }, []);
    return (_jsxs(Stack, { children: [_jsx(Typography, { variant: "body1", mb: 2, children: t("create-qr-application-description") }), _jsx(Controller, { control: form.control, name: "app-name", rules: { required: t("createqr-app-name-required") }, render: ({ field, formState }) => {
                    var _a;
                    return (_jsx(TextField, { ...field, size: "medium", style: { marginBottom: "10px" }, error: !!formState.errors["app-name"], helperText: ((_a = formState.errors["app-name"]) === null || _a === void 0 ? void 0 : _a.message) &&
                            `${t(formState.errors["app-name"].message)}`, label: t("createqr-app-name") }));
                } }), _jsx(Controller, { control: form.control, name: "app-android", rules: {
                    validate: (value, fromValues) => {
                        if (!value && !fromValues["app-ios"]) {
                            return t("createqr-app-link-required");
                        }
                        if (value && !value.match(URLRegexValidator)) {
                            return t("createqr-website-url-required");
                        }
                        clearErrors("app-ios");
                        return true;
                    },
                }, render: ({ field, formState }) => {
                    var _a;
                    return (_jsx(TextField, { ...field, size: "medium", style: { marginBottom: "10px" }, error: !!formState.errors["app-android"], helperText: ((_a = formState.errors["app-android"]) === null || _a === void 0 ? void 0 : _a.message) &&
                            `${t(formState.errors["app-android"].message)}`, label: t("createqr-app-android") }));
                } }), _jsx(Controller, { control: form.control, name: "app-ios", rules: {
                    validate: (value, fromValues) => {
                        if (!value && !fromValues["app-android"]) {
                            return t("createqr-app-link-required");
                        }
                        if (value && !value.match(URLRegexValidator)) {
                            return t("createqr-website-url-required");
                        }
                        clearErrors("app-android");
                        return true;
                    },
                }, render: ({ field, formState }) => {
                    var _a;
                    return (_jsx(TextField, { ...field, size: "medium", style: { marginBottom: "10px" }, error: !!formState.errors["app-ios"], helperText: ((_a = formState.errors["app-ios"]) === null || _a === void 0 ? void 0 : _a.message) &&
                            `${t(formState.errors["app-ios"].message)}`, label: t("createqr-app-ios") }));
                } })] }));
};
export default Application;
