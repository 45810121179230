import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { HexColorPicker } from "react-colorful";
import { PanelTitle } from "./Panel";
const ColorPicker = ({ color, onChange, label }) => {
    return (_jsxs(Box, { children: [_jsx(PanelTitle, { text: label, variant: "h4" }), _jsx(HexColorPicker, { color: color, onChange: onChange, style: {
                    width: "100%",
                } })] }));
};
export default ColorPicker;
