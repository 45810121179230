import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckCircleOutline } from "@mui/icons-material";
import { Box, List, ListItem, ListItemIcon, ListItemText, Stack, Typography, styled, } from "@mui/material";
import { useTranslation } from "react-i18next";
const StyledBox = styled(Stack)(({ theme }) => ({
    flexDirection: "column",
    justifyContent: "space-between",
    border: "solid",
    borderRadius: "10px",
    borderColor: theme.palette.grey[300],
    padding: "20px",
    margin: "20px",
    maxWidth: "454px",
    [theme.breakpoints.up("sm")]: {
        width: "50%",
    },
}));
const PlanContentBox = ({ heading, desc, }) => {
    return (_jsxs(Box, { sx: {
            background: "#F2F7FF",
            borderRadius: "14px",
            paddingY: "20px",
        }, children: [_jsx(Typography, { variant: "h2", color: "#2F54EB", textAlign: "center", children: heading }), _jsx(Typography, { textAlign: "center", component: "h2", variant: "h2", children: desc })] }));
};
const ItemStack = ({ items }) => {
    return (_jsx(List, { dense: true, children: items.map((item, index) => {
            return (_jsxs(ListItem, { children: [_jsx(ListItemIcon, { children: _jsx(CheckCircleOutline, { color: "primary" }) }), _jsx(ListItemText, { primary: item })] }, index));
        }) }));
};
export default function PriceComponent() {
    const { t } = useTranslation();
    const trailItems = [
        t("trial-advantage-one"),
        t("trial-advantage-two"),
        t("trial-advantage-three"),
    ];
    const monthlyItems = [
        t("monthly-plan-one"),
        t("monthly-plan-two"),
        t("monthly-plan-three"),
    ];
    return (_jsxs(Box, { sx: { padding: "0 5%" }, children: [_jsxs(Box, { sx: { marginTop: "10%", marginBottom: "5%" }, children: [_jsx(Typography, { textAlign: "center", variant: "h1", component: "h1", children: t("plans-and-prices") }), _jsx(Typography, { textAlign: "center", children: t("plans-and-prices-desc") })] }), _jsxs(Stack, { direction: { xs: "column", sm: "row" }, justifyContent: "center", children: [_jsxs(StyledBox, { children: [_jsx(PlanContentBox, { heading: t("trial-offer"), desc: t("trial-offer-price") }), _jsx(Typography, { textAlign: "center", paddingTop: "1.5rem", children: t("trial-advantage-desc") }), _jsx(ItemStack, { items: trailItems })] }), _jsxs(StyledBox, { children: [_jsx(PlanContentBox, { heading: t("monthly-plan"), desc: t("monthly-plan-price") }), _jsx(Typography, { textAlign: "center", paddingTop: "1.5rem", children: t("monthly-plan-desc") }), _jsx(ItemStack, { items: monthlyItems })] })] }), _jsx(Box, { marginX: "3%", marginTop: "1rem", children: _jsx(Typography, { textAlign: "center", variant: "body2", children: t("after-48-hours") }) })] }));
}
