import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DeleteSweep, Download, QrCodeScanner, QueryStats, } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, List, ListItem, ListItemIcon, ListItemText, Stack, Typography, styled, useMediaQuery, } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ScannerBackdrop from "../../components/common/ScannerBackdrop";
import ScannerButton from "../../components/common/ScannerButton";
import { getToken } from "../../context/AuthProvider";
const StyledStack = styled(Stack)(() => ({
    justifyContent: "center",
}));
const QRListItem = ({ id, name, type, createdAt, qrCodeUrl, handleDelete, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const deleteMe = () => {
        handleClose();
        handleDelete(id);
    };
    return (_jsxs(ListItem, { disablePadding: true, sx: {
            borderStyle: "solid",
            borderRadius: "10px",
            borderColor: "lightgray",
            backgroundColor: "background.default",
            margin: "1rem auto",
        }, children: [_jsx(ListItemIcon, { sx: { display: { xs: "none" } }, children: _jsx(QrCodeScanner, {}) }), _jsxs(Stack, { sx: {
                    width: "100%",
                    flexDirection: "row",
                    gap: { sm: "2%" },
                    paddingX: "2%",
                }, children: [_jsx(ListItemText, { primary: `${t("dashboard-list-type")}: ${type}`, secondary: `${name} - ${t("dashboard-list-created")}: ${createdAt}` }), _jsx(StyledStack, { children: _jsx(Link, { href: `/dashboard/analyze?qrID=${id}`, children: _jsx(Button, { startIcon: _jsx(QueryStats, {}), sx: { minWidth: "inherit" }, children: _jsx(Typography, { sx: { display: { xs: "none", sm: "block" } }, variant: "caption", children: t("dashboard-list-analysis") }) }) }) }), _jsx(StyledStack, { children: _jsx("a", { href: qrCodeUrl, children: _jsx(ScannerButton, { endIcon: _jsx(Download, {}), sx: { minWidth: "inherit" }, children: _jsx(Typography, { sx: { display: { xs: "none", sm: "block" } }, variant: "caption", children: t("dashboard-list-download") }) }) }) }), _jsx(StyledStack, { children: _jsx(Button, { sx: { color: "red", minWidth: "inherit" }, endIcon: _jsx(DeleteSweep, {}), onClick: handleClickOpen }) }), _jsxs(Dialog, { fullScreen: fullScreen, open: open, onClose: handleClose, "aria-labelledby": "responsive-dialog-title", children: [_jsx(DialogTitle, { id: "responsive-dialog-title", children: t("dashboard-list-delete-confirmation") }), _jsx(DialogContent, { children: _jsx(DialogContentText, { children: t("dashboard-list-delete-content") }) }), _jsxs(DialogActions, { children: [_jsx(Button, { autoFocus: true, onClick: handleClose, children: t("dashboard-list-delete-cancel") }), _jsx(Button, { onClick: deleteMe, autoFocus: true, children: t("dashboard-list-delete-confirm") })] })] })] })] }));
};
const QRTab = () => {
    var _a;
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const query = useQuery({
        queryKey: ["dashboardCodes"],
        queryFn: async () => {
            return await axios.get("/api/dashboard-codes", {
                withCredentials: true,
                headers: { Authorization: "Bearer " + getToken() },
            });
        },
    });
    const mutation = useMutation({
        mutationFn: (id) => {
            return axios.delete("/api/code", {
                withCredentials: true,
                headers: { Authorization: "Bearer " + getToken() },
                data: { id: id },
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["dashboardCodes"] });
        },
    });
    const handleDelete = (codeid) => {
        mutation.mutate(codeid);
    };
    if (query.error instanceof Error)
        return (_jsx(Box, { sx: { textAlign: "center" }, children: _jsxs(Typography, { children: ["An error has occurred ", query.error.message] }) }));
    return (_jsxs(Stack, { sx: { padding: "1rem" }, children: [_jsx(ScannerButton, { href: "/create", sx: { fontSize: "1.2rem", marginX: "auto" }, children: t("createqr") }), _jsx(ScannerBackdrop, { open: query.isLoading }), _jsx(Typography, { children: t("dashboard-your-qr-codes") }), ((_a = query.data) === null || _a === void 0 ? void 0 : _a.data.length) ? (_jsx(List, { children: query.data.data.map((qr) => {
                    return (_jsx(QRListItem, { id: qr.id, name: qr.name, type: t(qr.type), createdAt: qr.createdAt, qrCodeUrl: qr.qrCodeUrl, handleDelete: handleDelete }, qr.id));
                }) })) : (_jsx(Typography, { variant: "body2", children: t("dashboard-list-no-avail") }))] }));
};
export default QRTab;
