import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckCircle } from "@mui/icons-material";
import { Box, Button, Stack, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import imgSrc from "./right-arrow.svg";
const CheckCircleBlue = styled(CheckCircle)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: "1.2rem",
}));
const ListItem = ({ label }) => {
    return (_jsxs(Stack, { sx: {
            flexDirection: "row",
            alignItems: "center",
            columnGap: "0.6rem",
        }, children: [_jsx(CheckCircleBlue, {}), _jsx(Typography12px400, { variant: "body2", children: label })] }));
};
const Typography25px600Black = styled(Typography) `
  font-size: 25px;
  font-weight: 600;
  color: #000;
  margin: 0;
`;
const Typography12px500Black = styled(Typography) `
  font-size: 12px;
  font-weight: 500;
  color: #000;
`;
const Typography12px500Gray = styled(Typography12px500Black) `
  color: #8a8c91;
`;
const Typography12px500White = styled(Typography12px500Black) `
  color: #fff;
`;
const Typography12px400 = styled(Typography) `
  font-size: 12px;
  font-weight: 400;
  color: #000;
`;
function Price() {
    const { t } = useTranslation();
    const onClick = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    return (_jsx(Stack, { sx: { alignItems: "center", marginY: "5rem" }, children: _jsx(Stack, { sx: {
                maxWidth: "803px",
            }, children: _jsxs(Stack, { sx: {
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: "center",
                    rowGap: "1.5rem",
                    columnGap: "1.5rem",
                    margin: "2rem 3rem",
                }, children: [_jsxs(Stack, { sx: { textAlign: "center", marginRight: "1rem", rowGap: "2rem" }, children: [_jsxs(Box, { children: [_jsx(Typography25px600Black, { variant: "h3", children: t("price-monthly") }), _jsx(Typography12px500Gray, { variant: "body2", children: t("price-bill-cancellation") })] }), _jsxs(Stack, { sx: {
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    columnGap: "1rem",
                                }, children: [_jsxs(Stack, { children: [_jsx(Typography25px600Black, { children: t("price-one-euro") }), _jsx(Typography12px500Black, { variant: "body2", color: "#8A8C91", children: t("price-48-trial") })] }), _jsx(Stack, { sx: {
                                            justifyContent: "center",
                                            textAlign: "left",
                                            width: "80px",
                                            height: "24px",
                                            backgroundImage: `url(${imgSrc})`,
                                            backgroundRepeat: "no-repeat",
                                        }, children: _jsx(Typography12px500White, { variant: "body2", sx: {
                                                marginLeft: "0.7rem",
                                            }, children: t("price-48-hours") }) }), _jsxs(Stack, { children: [_jsx(Typography25px600Black, { children: t("price-29-euro") }), _jsx(Typography12px500Black, { variant: "body2", children: t("price-per-month") })] })] }), _jsx(Stack, { children: _jsx(Button, { onClick: onClick, variant: "contained", children: t("price-pay-now") }) })] }), _jsxs(Stack, { rowGap: "1rem", children: [_jsx(ListItem, { label: t("price-list-one") }), _jsx(ListItem, { label: t("price-list-two") }), _jsx(ListItem, { label: t("price-list-three") }), _jsx(ListItem, { label: t("price-list-four") }), _jsx(ListItem, { label: t("price-list-five") }), _jsx(ListItem, { label: t("price-list-six") })] })] }) }) }));
}
export default Price;
