import { jsx as _jsx } from "react/jsx-runtime";
import axios from "axios";
import { createContext, useState } from "react";
export const getToken = () => {
    return localStorage.getItem("token");
};
let logout = () => {
    console.debug("Logout called without active auth provider");
};
const getNewJwt = async () => {
    const refreshTokenEndpoint = "/api/token/refresh";
    const storedRefreshToken = localStorage.getItem("refreshToken");
    try {
        const response = await axios.post(refreshTokenEndpoint, {
            refreshToken: storedRefreshToken,
        });
        if (response.data && response.data.token) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("refreshToken", response.data.refreshToken);
            return response.data.token;
        }
        else {
            throw new Error("Error getting new JWT");
        }
    }
    catch (error) {
        console.error("Error getting new JWT:", error);
        return null;
    }
};
// Intercept response and handle JWT refresh on a 401 error
axios.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    const originalRequest = error.config;
    if (originalRequest.url !== "/api/token/refresh" &&
        error.response.status === 401 &&
        !originalRequest._retry) {
        originalRequest._retry = true;
        const newJwt = await getNewJwt();
        if (newJwt) {
            originalRequest.headers.Authorization = `Bearer ${newJwt}`;
            return axios(originalRequest);
        }
        else {
            logout();
            return Promise.reject(error);
        }
    }
    if (error.response.status !== 401) {
        logout();
    }
    return Promise.reject(error);
});
const ensureSession = async () => {
    try {
        // If the JWT is expired this will trigger a 401 which will be intercepted in the axios interceptor above. A new JWT will be requested, refreshing the session.
        // If the JWT is expired and the refresh token is expired, the user will be logged out by the code above.
        await axios.get("/api/user", {
            withCredentials: true,
            headers: { Authorization: "Bearer " + getToken() },
        });
    }
    catch (error) {
        console.error(error);
    }
};
if (getToken()) {
    ensureSession();
}
const AuthContext = createContext({});
const getSession = () => {
    const auth = {
        token: "",
        email: "",
        refreshToken: "",
    };
    if (localStorage.getItem("token") !== undefined) {
        auth.token = localStorage.getItem("token");
    }
    if (localStorage.getItem("refreshToken") !== undefined) {
        auth.refreshToken = localStorage.getItem("refreshToken");
    }
    if (localStorage.getItem("email") !== undefined) {
        auth.email = localStorage.getItem("email");
    }
    return auth;
};
export const AuthProvider = ({ children }) => {
    const auth = getSession();
    const [session, setSession] = useState({ email: auth.email } || "");
    const signin = (email, token, refreshToken) => {
        setSession({ email: email });
        localStorage.setItem("email", email);
        localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", refreshToken);
    };
    const signout = () => {
        setSession({ email: "" });
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        localStorage.removeItem("refreshToken");
    };
    logout = signout;
    return (_jsx(AuthContext.Provider, { value: { session, signin, signout }, children: children }));
};
export default AuthContext;
