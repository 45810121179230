import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowForward } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useRef } from "react";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import loginPath from "../../../img/scanner/login/login.svg";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ScannerLoadingButton from "../components/common/ScannerLoadingButton";
const validationSchema = z.object({
    email: z.string().email("reset-pass-email-required").min(1),
});
const ResetPassword = () => {
    const { t } = useTranslation();
    const emailRef = useRef(null);
    const mutation = useMutation({
        mutationFn: (email) => {
            return axios.post("/api/request-password-email", JSON.stringify({ email: email }), {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        },
    });
    useEffect(() => {
        var _a;
        (_a = emailRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Header, {}), _jsxs(Stack, { sx: {
                    padding: "0 5%",
                    flexFlow: "row-reverse wrap",
                    marginTop: "10%",
                    alignItems: "center",
                    gap: "3rem",
                }, children: [_jsxs(Stack, { sx: { flex: "1 1 400px" }, children: [_jsx(Typography, { variant: "h1", component: "h1", children: t("reset-pass-title") }), _jsx(Typography, { sx: { textAlign: "justify" }, children: t("reset-pass-desc") }), mutation.isSuccess ? (_jsx(Box, { children: _jsx(Typography, { variant: "caption", sx: { color: "success.light" }, children: t("reset-pass-sent") }) })) : null, _jsxs(FormContainer, { resolver: zodResolver(validationSchema), defaultValues: {
                                    email: "",
                                }, onSuccess: (data) => {
                                    mutation.mutate(data.email);
                                }, children: [_jsx(TextFieldElement, { fullWidth: true, parseError: (err) => (err.message ? t(err.message) : ""), name: "email", label: t("login-email") }), _jsx(ScannerLoadingButton, { loading: mutation.isLoading, type: "submit", endIcon: _jsx(ArrowForward, {}), children: t("reset-pass-button") })] })] }), _jsx(Box, { sx: {
                            flex: "1 0 50%",
                            textAlign: "center",
                        }, children: _jsx(Box, { sx: { maxWidth: "90%" }, component: "img", alt: "topqr login image", src: loginPath }) })] }), _jsx(Footer, {})] }));
};
export default ResetPassword;
