import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppBlocking, Feed, Image, Language, List, OndemandVideo, PictureAsPdf, Wifi, } from "@mui/icons-material";
import { Button, FormControl, InputLabel, MenuItem, Select, } from "@mui/material";
import { Box } from "@mui/material";
import { Stack } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCreateQR } from "../../context/CreateQRProvider";
export default function CreateMenu({ activeStep }) {
    const { setQRType, qrType, setContentForm } = useCreateQR();
    const validTypes = [
        { value: "website", Icon: Language },
        { value: "application", Icon: AppBlocking },
        { value: "links", Icon: List },
        { value: "wifi", Icon: Wifi },
        { value: "vcard", Icon: Feed },
        { value: "video", Icon: OndemandVideo },
        { value: "image", Icon: Image },
        { value: "pdf", Icon: PictureAsPdf },
    ];
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (_jsxs(Stack, { sx: {
            height: activeStep !== 1 ? "auto" : 0,
            overflowX: "auto",
            overflowY: "hidden",
        }, children: [_jsx(Box, { py: 3, sx: {
                    display: {
                        xs: "flex",
                        lg: "none",
                    },
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    width: "100%",
                }, children: activeStep !== 1 && (_jsxs(FormControl, { fullWidth: true, sx: {
                        maxWidth: 420,
                    }, children: [_jsx(InputLabel, { children: "QR Type" }), _jsx(Select, { value: qrType, label: "QR Type", onChange: (e) => {
                                setQRType(e.target.value);
                                if (activeStep !== 0) {
                                    navigate("/create");
                                }
                            }, children: validTypes.map(({ value }) => (_jsx(MenuItem, { value: value, children: t(`createqr-${value}`) }, value))) })] })) }), _jsx(Stack, { justifyContent: "center", flexDirection: "row", gap: 2, p: 2, sx: {
                    display: {
                        xs: "none",
                        lg: "flex",
                    },
                    mb: 2,
                }, children: activeStep !== 1 &&
                    validTypes.map(({ Icon, value }) => (_jsx(Button, { startIcon: _jsx(Icon, {}), onClick: () => {
                            setQRType(value);
                            if (activeStep !== 0) {
                                navigate("/create");
                            }
                        }, disableElevation: true, variant: qrType === value ? "contained" : "outlined", size: "large", color: qrType === value ? "primary" : "inherit", sx: {
                            borderRadius: "6px",
                            borderColor: "transparent",
                            flex: "1 0 auto",
                        }, children: t(`createqr-${value}`) }, value))) })] }));
}
