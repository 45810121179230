import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, FormLabel, Stack, Typography, } from "@mui/material";
export const PanelTitle = ({ text, variant = "h4", }) => (_jsxs(FormLabel, { id: "radio-buttons-group-label", sx: {
        mt: 0,
        mb: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 2,
    }, children: [_jsx(Typography, { variant: variant, sx: {
                mb: 1,
                textTransform: "uppercase",
                fontSize: {
                    xs: 24,
                    sm: 28,
                    md: 32,
                },
                color: "black",
                fontWeight: 700,
            }, children: text }), _jsx(Divider, { sx: {
                width: 150,
                height: 1,
                backgroundColor: "primary.main",
            } })] }));
export const Panel = ({ children }) => (_jsx(Stack, { sx: {
        // padding: "20px",
        // backgroundColor: "#FFFFFF",
        // border: "1px solid #dedede",
        // borderRadius: "8px",
        // boxShadow: "0px 2px 6px -1px rgba(0,0,0,0.1)",
        // mb: 2,
        p: 2,
        mb: 2,
        backgroundColor: "#F9FAFB",
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
        borderRadius: 1,
    }, children: children }));
