import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Review from "./Review";
import imgSrc1 from "./review_1.png";
import imgSrc2 from "./review_2.png";
import imgSrc3 from "./review_3.png";
function Satisfied() {
    const { t } = useTranslation();
    return (_jsxs(Stack, { sx: {
            pt: 8,
        }, children: [_jsx(Box, { sx: { textAlign: "center", marginBottom: "5rem" }, children: _jsx(Typography, { variant: "h3", sx: { fontSize: "30px !important", color: "#000" }, children: t("review-satisfied") }) }), _jsxs(Stack, { sx: {
                    position: "relative",
                }, children: [_jsx(Box, { sx: {
                            "&::before": {
                                content: '""',
                                position: "absolute",
                                background: "linear-gradient(0deg, rgba(47, 84, 235, 0.2) 90%, #F4F4F4 90%, white 100%, white)",
                                top: "0",
                                height: "100%",
                                width: "200vw",
                                left: "-100vw",
                                zIndex: "-1",
                            },
                        } }), _jsxs(Stack, { sx: {
                            flexDirection: { xs: "column", md: "row" },
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 2,
                            paddingBottom: "4rem",
                        }, children: [_jsx(Review, { imgsrc: imgSrc1, author: "Alice P.", title: t("review-title-1"), desc: t("review-desc-1") }), _jsx(Review, { imgsrc: imgSrc2, author: "Maxime D.", title: t("review-title-2"), desc: t("review-desc-2") }), _jsx(Review, { imgsrc: imgSrc3, author: "Julie C.", title: t("review-title-3"), desc: t("review-desc-3") })] })] }), _jsx(Stack, { alignItems: "center", children: _jsxs(Box, { sx: {
                        background: "rgba(249, 250, 251, 0.9)",
                        borderRadius: "50px",
                        width: "70%",
                        padding: "1.5rem 2rem",
                        marginY: "5rem",
                        textAlign: "center",
                    }, children: [_jsx(Box, { marginBottom: "1rem", children: _jsx(Typography, { variant: "h3", color: "#000000", children: t("review-qr-over-10500-title") }) }), _jsx(Typography, { variant: "body2", color: "#000000", fontWeight: "500", children: t("review-qr-over-10500-desc") })] }) }), _jsx(Stack, { sx: { alignItems: "center" }, children: _jsxs(Box, { sx: { textAlign: "center", maxWidth: "900px" }, children: [_jsx(Typography, { sx: { marginBottom: "0.5rem" }, variant: "body2", color: "#000000", fontWeight: "400", children: t("review-doubts-effectiveness") }), _jsx(Typography, { variant: "body2", color: "#000000", fontWeight: "400", children: t("review-end-of-trial") })] }) })] }));
}
export default Satisfied;
