import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowForward } from "@mui/icons-material";
import { Box, FormHelperText, Stack, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios, { isAxiosError } from "axios";
import { useState } from "react";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";
import loginPath from "../../../img/scanner/login/login.svg";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ScannerLoadingButton from "../components/common/ScannerLoadingButton";
const validationSchema = z.object({
    firstPass: z.string().min(4, "update-pass-min"),
    secondPass: z.string().min(4, "update-pass-min"),
});
const UpdatePassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const [errMsg, setErrMsg] = useState("");
    const mutation = useMutation({
        mutationFn: (variables) => {
            return axios.post("/api/reset-password", JSON.stringify({
                formData: {
                    token: token,
                    plainPassword: { first: variables.first, second: variables.second },
                },
            }), {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        },
        onSuccess: () => {
            navigate("/login", { replace: true });
        },
        onError(error) {
            var _a;
            if (isAxiosError(error)) {
                setErrMsg((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.error);
            }
        },
    });
    return (_jsxs(_Fragment, { children: [_jsx(Header, {}), _jsxs(Stack, { sx: {
                    padding: "0 5%",
                    flexFlow: "row-reverse wrap",
                    marginTop: "10%",
                    alignItems: "center",
                    gap: "3rem",
                }, children: [_jsxs(Stack, { sx: { flex: "1 1 400px" }, children: [_jsx(Typography, { variant: "h1", component: "h1", children: t("update-pass-title") }), _jsx(Typography, { sx: { textAlign: "justify" }, children: t("update-pass-desc") }), _jsxs(FormContainer, { resolver: zodResolver(validationSchema), defaultValues: {
                                    firstPass: "",
                                    secondPass: "",
                                }, onSuccess: (data) => {
                                    mutation.mutate({
                                        first: data.firstPass,
                                        second: data.secondPass,
                                    });
                                }, children: [_jsx(TextFieldElement, { fullWidth: true, parseError: (err) => (err.message ? t(err.message) : ""), name: "firstPass", label: t("update-pass") }), _jsx(TextFieldElement, { fullWidth: true, parseError: (err) => (err.message ? t(err.message) : ""), name: "secondPass", label: t("update-pass-confirm") }), _jsx(ScannerLoadingButton, { type: "submit", endIcon: _jsx(ArrowForward, {}), loading: mutation.isLoading, children: t("update-pass-button") })] }), _jsx(FormHelperText, { error: Boolean(errMsg), children: t(errMsg) })] }), _jsx(Box, { sx: {
                            flex: "1 0 50%",
                            textAlign: "center",
                        }, children: _jsx(Box, { sx: { maxWidth: "90%" }, component: "img", alt: "topqr login image", src: loginPath }) })] }), _jsx(Footer, {})] }));
};
export default UpdatePassword;
