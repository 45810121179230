import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { zodResolver } from "@hookform/resolvers/zod";
import { TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useCreateQR } from "../../context/CreateQRProvider";
import { URLRegexValidator, focusInput } from "../../helpers";
const Website = () => {
    const { t } = useTranslation();
    const { setContentForm, currentForm, qrType } = useCreateQR();
    const form = useForm({
        resolver: zodResolver(z.object({
            "website-url": z
                .string()
                .regex(URLRegexValidator, "createqr-website-url-required"),
        })),
        defaultValues: currentForm && qrType === "website"
            ? currentForm.getValues()
            : {
                "website-url": "",
            },
    });
    useEffect(() => {
        setContentForm(form);
        focusInput("website-url");
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "body1", mb: 2, children: t("create-qr-website-description") }), _jsx(Controller, { control: form.control, name: "website-url", render: ({ field }) => {
                    var _a;
                    return (_jsx(_Fragment, { children: _jsx(TextField, { ...field, label: t("createqr-website-url"), inputProps: {
                                placeholder: t("createqr-website-url-placeholder"),
                            }, fullWidth: true, size: "medium", error: !!form.formState.errors["website-url"], helperText: ((_a = form.formState.errors["website-url"]) === null || _a === void 0 ? void 0 : _a.message) &&
                                `${t(form.formState.errors["website-url"].message)}` }) }));
                } })] }));
};
export default Website;
