export const focusInput = (name) => {
    const input = document.querySelector(`input[name=${name}]`);
    input === null || input === void 0 ? void 0 : input.focus();
};
// https://www.freecodecamp.org/news/check-if-a-javascript-string-is-a-url/
export const URLRegexValidator = new RegExp("^(https?:\\/\\/)?" + // validate protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
    "(\\#[-a-z\\d_]*)?$", "i");
