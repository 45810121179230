import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowForward } from "@mui/icons-material";
import { Box, Stack, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import personalizedImagePath from "../../../img/scanner/landing/personalized.svg";
import powerImagePath from "../../../img/scanner/landing/power.svg";
import scanImagePath from "../../../img/scanner/landing/scan.svg";
import simpleImagePath from "../../../img/scanner/landing/simple.svg";
import stepOneImagePath from "../../../img/scanner/landing/step-one-choose.svg";
import stepThreeImagePath from "../../../img/scanner/landing/step-three-download.svg";
import stepTwoImagePath from "../../../img/scanner/landing/step-two-customize.svg";
import Base from "../Base";
import Faq from "../components/Faq";
import PriceComponent from "../components/common/PriceComponent";
import ScannerButton from "../components/common/ScannerButton";
const StyledStack = styled(Stack)(({ theme }) => ({
    backgroundColor: theme.status.defaultBG,
}));
const CreateQRButton = () => {
    const { t } = useTranslation();
    return (_jsx(ScannerButton, { href: "/create", endIcon: _jsx(ArrowForward, {}), sx: {
            fontSize: {
                xs: 18,
                sm: 20,
                md: 24,
            },
        }, children: t("createqr") }));
};
function Home() {
    const { t } = useTranslation();
    return (_jsx(Base, { children: _jsxs(Box, { sx: { padding: "0 5%" }, children: [_jsxs(Stack, { sx: {
                        flexDirection: { xs: "column", sm: "row" },
                        margin: "10% 0",
                        justifyContent: "space-between",
                        alignItems: "center",
                        rowGap: "20px",
                    }, children: [_jsxs(Stack, { sx: {
                                width: { xs: "100%", sm: "50%" },
                                display: "flex",
                                alignItems: "left",
                            }, children: [_jsx(Typography, { variant: "h1", mb: 2, sx: {
                                        fontSize: {
                                            xs: 22,
                                            sm: 26,
                                            md: "3rem",
                                        },
                                    }, children: t("createqr-title") }), _jsx(Typography, { sx: {
                                        fontSize: {
                                            xs: 18,
                                            sm: 20,
                                            md: 24,
                                        },
                                        mb: {
                                            xs: 2,
                                            sm: 3,
                                            md: 4,
                                        },
                                    }, children: t("createqr-desc") }), _jsxs(Box, { sx: {
                                        p: 2,
                                        backgroundColor: "#F9FAFB",
                                        borderRadius: "8px",
                                    }, children: [_jsx(Box, { sx: { textAlign: "center" }, children: _jsx(CreateQRButton, {}) }), _jsx(Typography, { sx: { textAlign: "center" }, variant: "body2", children: t("online-simple-fast") })] })] }), _jsx(Box, { sx: { textAlign: "center", width: { xs: "100%", sm: "50%" } }, children: _jsx(Box, { draggable: false, sx: { maxWidth: "90%" }, component: "img", alt: "topqr scan now", src: scanImagePath }) })] }), _jsxs(StyledStack, { sx: { margin: "5% 0", padding: "5%", justifyContent: "center" }, children: [_jsx(Typography, { variant: "h2", sx: { fontWeight: "fontWeightMedium", textAlign: "center" }, children: t("how-to-qr") }), _jsxs(Stack, { sx: {
                                gap: "5%",
                                paddingTop: "5%",
                                flexDirection: { xs: "column", sm: "row" },
                                justifyContent: "space-between",
                            }, children: [_jsxs(Box, { sx: { textAlign: "center", flex: 1 }, children: [_jsx(Box, { sx: { maxWidth: "100%" }, component: "img", alt: "topqr step one", src: stepOneImagePath }), _jsx(Typography, { sx: { textAlign: "center" }, children: t("step-one-choose-form") })] }), _jsxs(Box, { sx: { textAlign: "center", flex: 1 }, children: [_jsx(Box, { sx: { maxWidth: "100%" }, component: "img", alt: "topqr step two", src: stepTwoImagePath }), _jsx(Typography, { sx: { textAlign: "center" }, children: t("step-two-customize") })] }), _jsxs(Box, { sx: { textAlign: "center", flex: 1 }, children: [_jsx(Box, { sx: { maxWidth: "100%" }, component: "img", alt: "topqr step three", src: stepThreeImagePath }), _jsx(Typography, { sx: { textAlign: "center" }, children: t("step-three-download") })] })] })] }), _jsx(Box, { sx: { margin: "5% 0", textAlign: "center" }, children: _jsx(CreateQRButton, {}) }), _jsxs(Stack, { sx: {
                        margin: "5% 0",
                        flexDirection: { xs: "column", sm: "row" },
                        justifyContent: "space-between",
                        gap: "10%",
                        rowGap: "20px",
                    }, children: [_jsx(Stack, { sx: {
                                width: { xs: "100%", sm: "50%" },
                                justifyContent: "center",
                                alignItems: "center",
                            }, children: _jsx(Box, { sx: { maxWidth: "90%" }, component: "img", alt: "topqr simple image", src: simpleImagePath }) }), _jsxs(Stack, { sx: { width: { xs: "100%", sm: "50%" }, justifyContent: "center" }, children: [_jsx(Typography, { variant: "h2", children: t("simple-accessible-title") }), _jsx(Typography, { marginTop: "1.5rem", children: t("simple-accessible-desc-one") }), _jsx(Typography, { marginTop: ".5rem", children: t("simple-accessible-desc-two") })] })] }), _jsxs(Stack, { sx: {
                        margin: "5% 0",
                        flexDirection: { xs: "column", sm: "row" },
                        justifyContent: "space-between",
                        gap: "10%",
                        rowGap: "20px",
                    }, children: [_jsxs(Stack, { sx: { width: { xs: "100%", sm: "50%" }, justifyContent: "center" }, order: { xs: 2, sm: 1 }, children: [_jsx(Typography, { variant: "h2", children: t("personalized-title") }), _jsx(Typography, { marginTop: "1.5rem", children: t("personalized-desc-one") }), _jsx(Typography, { marginTop: "0.5rem", children: t("personalized-desc-two") })] }), _jsx(Stack, { sx: {
                                width: { xs: "100%", sm: "50%" },
                                justifyContent: "center",
                                alignItems: "center",
                            }, order: { xs: 1, sm: 2 }, children: _jsx(Box, { sx: { maxWidth: "90%" }, component: "img", alt: "topqr personalized image", src: personalizedImagePath }) })] }), _jsxs(Stack, { sx: {
                        margin: "5% 0",
                        flexDirection: { xs: "column", sm: "row" },
                        justifyContent: "space-between",
                        gap: "10%",
                        rowGap: "20px",
                    }, children: [_jsx(Stack, { sx: {
                                width: { xs: "100%", sm: "50%" },
                                justifyContent: "center",
                                alignItems: "center",
                            }, children: _jsx(Box, { sx: { maxWidth: "90%" }, component: "img", alt: "topqr power image", src: powerImagePath }) }), _jsxs(Box, { sx: { width: { xs: "100%", sm: "50%" }, justifyContent: "center" }, children: [_jsx(Typography, { variant: "h2", children: t("power-accessibility-title") }), _jsx(Typography, { marginTop: "1.5rem", children: t("power-accessibility-desc-one") }), _jsx(Typography, { marginTop: "0.5rem", children: t("power-accessibility-desc-two") })] })] }), _jsx(Stack, { sx: {
                        margin: "8% 0",
                        flexDirection: "row",
                        justifyContent: "center",
                    }, children: _jsxs(Stack, { sx: { width: { xs: "100%", sm: "60%" } }, children: [_jsx(Typography, { sx: { textAlign: "center" }, variant: "h2", children: t("get-started-title") }), _jsx(Typography, { textAlign: "center", marginY: "1.2rem", children: t("get-started-desc") }), _jsx(Box, { sx: { marginTop: "5%", textAlign: "center" }, children: _jsx(CreateQRButton, {}) })] }) }), _jsx(Box, { marginBottom: "3rem", children: _jsx(PriceComponent, {}) }), _jsx(Box, { children: _jsx(Faq, {}) })] }) }));
}
export default Home;
