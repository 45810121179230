import { jsx as _jsx } from "react/jsx-runtime";
import { Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
const CookiesBanner = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        if (localStorage.getItem("bannerSeen") === "true") {
            return;
        }
        setIsVisible(true);
        localStorage.setItem("bannerSeen", "true");
    }, []);
    return isVisible ? (_jsx(CookieConsent, { location: "bottom", buttonText: t("cookies-btn-text"), cookieName: "topqr cookie", style: {
            background: `${theme.palette.background.paper}B2`,
            fontFamily: theme.typography.fontFamily,
        }, contentStyle: {
            margin: "10px 15px",
        }, buttonStyle: {
            background: theme.palette.primary.main + "B2",
            color: "white",
            fontFamily: theme.typography.fontFamily,
            borderRadius: "0.2rem",
            fontSize: "0.8rem",
            marginRight: "3rem",
        }, expires: 150, children: _jsx(Typography, { variant: "body2", sx: { color: "#000", fontSize: { xs: "0.8rem", md: "1.1rem" } }, children: t("cookies-message") }) })) : null;
};
export default CookiesBanner;
