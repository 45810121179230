import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
export default function UploadComponent({ type, accept, label, form, mutation, }) {
    const { t } = useTranslation();
    return (_jsxs(Box, { sx: { textAlign: "center" }, children: [_jsx("input", { type: "hidden", ...form.register("file-url") }), _jsx(Controller, { control: form.control, name: "file", render: ({ field }) => {
                    const onChange = (e) => {
                        var _a, _b;
                        field.onChange(e);
                        const file = (_b = (_a = e.target) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0];
                        if (file) {
                            const formData = new FormData();
                            formData.append("file", file);
                            formData.append("type", type);
                            mutation.mutate(formData, {
                                onSuccess: () => {
                                    form.setValue("file-name", file.name);
                                },
                            });
                        }
                    };
                    return (_jsx(LoadingButton, { size: "large", variant: "contained", component: "label", loading: mutation.isLoading, sx: {
                            borderRadius: "20px",
                            textTransform: "none",
                            fontSize: "1.3rem",
                        }, children: _jsxs("span", { children: [t(label), _jsx("input", { hidden: true, accept: accept, type: "file", ...field, onChange })] }) }));
                } }), _jsx(Typography, { variant: "subtitle1", children: form.getValues()["file-name"] &&
                    form.getValues()["file-name"] + t("createqr-uploaded") })] }));
}
