import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { zodResolver } from "@hookform/resolvers/zod";
import { CheckCircleOutline, CloseRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Modal, Stack, TextField, Typography, } from "@mui/material";
import { animated, useTransition } from "@react-spring/web";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import animationData from "../../../img/scanner/create/lottie_tracking_anim.json";
import { useCreateQR } from "../context/CreateQRProvider";
export default function CreateModal({ onResolve, onReject, }) {
    const [visibleItems, setVisibleItems] = useState([]);
    const { t } = useTranslation();
    const { setEmailForm, creatingQRCode } = useCreateQR();
    const [emailVisible, setEmailVisible] = useState(false);
    const items = [
        t("createqr-animation-1"),
        t("createqr-animation-2"),
        t("createqr-animation-3"),
    ];
    const getDelay = (i) => {
        return 1000 * i;
    };
    useEffect(() => {
        setEmailForm(form);
        const timeouts = [];
        items.forEach((item, index) => {
            const delay = getDelay(index);
            const timeout = setTimeout(() => {
                setVisibleItems((prevItems) => [...prevItems, item]);
            }, index * delay);
            timeouts.push(timeout);
        });
        return () => {
            setVisibleItems([]);
            timeouts.forEach((timeout) => clearTimeout(timeout));
        };
    }, []);
    useEffect(() => {
        if (visibleItems.length === items.length) {
            setTimeout(() => {
                setEmailVisible(true);
            }, 1000);
        }
    }, [visibleItems.length]);
    const transitions = useTransition(visibleItems, {
        from: { opacity: 0, transform: "translate3d(0, -40px, 0)" },
        enter: { opacity: 1, transform: "translate3d(0, 0px, 0)" },
    });
    const form = useForm({
        mode: "onTouched",
        resolver: zodResolver(z.object({
            email: z.string().email("createqr-email-required"),
        })),
        defaultValues: {
            email: localStorage.getItem("onboardingEmail") || "",
        },
    });
    const { handleSubmit } = form;
    return (_jsx(Modal, { open: true, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description", children: _jsxs(Box, { sx: {
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
                textAlign: "center",
            }, children: [" ", _jsx(Box, { onClick: onReject, sx: {
                        top: "0",
                        right: "0",
                        width: "30px",
                        height: "30px",
                        position: "absolute",
                        cursor: "pointer",
                        transform: "translate(50%, -50%)",
                        background: "white",
                        borderRadius: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }, children: _jsx(CloseRounded, {}) }), _jsx(Typography, { children: emailVisible
                        ? t("createqr-animation-title-2")
                        : t("createqr-animation-title-1") }), _jsxs(Stack, { sx: {
                        alignItems: {
                            md: "center",
                            sm: "flex-start",
                        },
                        flexDirection: {
                            md: "row",
                            sm: "column",
                        },
                    }, children: [_jsx(Stack, { sx: { width: { xs: "100%", md: "238.5px" }, alignItems: "center" }, children: _jsx(Lottie, { animationData: animationData, loop: 3, style: {
                                    width: 477 / 2,
                                    height: 540 / 2,
                                } }) }), _jsx(Stack, { gap: 2, sx: {
                                width: { xs: "280px", md: "380px" },
                                height: "160px",
                                mt: { md: "2rem" },
                                ml: {
                                    md: 4,
                                    sm: 0,
                                },
                            }, children: emailVisible ? (_jsxs("form", { onSubmit: handleSubmit(onResolve), children: [_jsx(Controller, { control: form.control, name: "email", render: ({ field }) => {
                                            var _a;
                                            return (_jsx(Box, { children: _jsx(TextField, { fullWidth: true, ...field, error: !!form.formState.errors.email, helperText: ((_a = form.formState.errors.email) === null || _a === void 0 ? void 0 : _a.message) &&
                                                        `${t(form.formState.errors.email.message)}`, label: t("createqr-email-label"), sx: {
                                                        mb: 1,
                                                    } }) }));
                                        } }), _jsx(LoadingButton, { type: "submit", variant: "contained", sx: { mt: 2 }, loading: creatingQRCode.status === "pending", children: _jsx("span", { children: t("createqr-email-continue-button") }) })] })) : (transitions((style, item) => (_jsx(animated.div, { style: style, children: _jsxs(Stack, { alignItems: "center", justifyItems: "center", direction: "row", children: [_jsx(CheckCircleOutline, { color: "success", sx: { mr: 2 } }), _jsx(Typography, { variant: "body2", children: item })] }) }, item)))) })] })] }) }));
}
