import { jsx as _jsx } from "react/jsx-runtime";
import { Step, StepLabel, Stepper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
export default function CreateStepper({ activeStep, steps, isMobile, }) {
    const { t } = useTranslation();
    return (_jsx(Box, { sx: {
            backgroundColor: "#F3F4F6",
        }, children: _jsx(Stepper, { activeStep: activeStep, sx: {
                maxWidth: 666,
                margin: "0 auto 0",
                paddingX: { xs: 0, md: "30px" },
                paddingY: { xs: "30px", md: "15px" },
            }, children: steps.map((step, index) => (_jsx(Step, { children: _jsx(StepLabel, { children: isMobile ? (_jsx(Typography, { variant: "h5", sx: { fontSize: "0.82rem" }, children: index === activeStep ? t(step) : null })) : (_jsx(Typography, { variant: "h5", sx: { fontSize: "0.82rem" }, children: t(step) })) }) }, step))) }) }));
}
