import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { zodResolver } from "@hookform/resolvers/zod";
import { Add, Cancel } from "@mui/icons-material";
import { Box, Button, IconButton, Stack, TextField, Typography, styled, } from "@mui/material";
import { useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useCreateQR } from "../../context/CreateQRProvider";
import { URLRegexValidator } from "../../helpers";
const StyledStack = styled(Stack)({
    margin: "0 0 15px",
    borderRadius: "8px",
    background: "#F9FAFB",
    padding: ".5rem 0 1rem .5rem",
});
const Links = () => {
    const { t } = useTranslation();
    const { setContentForm, currentForm, qrType } = useCreateQR();
    const form = useForm({
        resolver: zodResolver(z.object({
            links: z.array(z.object({
                text: z.string().min(1, "required"),
                link: z.string().regex(URLRegexValidator, "required"),
            })),
        })),
        defaultValues: currentForm && qrType === "links"
            ? currentForm.getValues()
            : {
                links: [{ text: "", link: "" }],
            },
    });
    const { control } = form;
    const { fields, append, remove } = useFieldArray({
        control,
        name: "links",
    });
    useEffect(() => {
        setContentForm(form);
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "body1", mb: 2, children: t("create-qr-links-description") }), fields.map((item, index) => {
                return (_jsxs(Box, { style: {
                        position: "relative",
                    }, children: [_jsxs(StyledStack, { gap: 1, justifyContent: "center", sx: {
                                // backgroundColor: "#FFFFFF",
                                border: "1px solid #dedede",
                                borderRadius: "8px",
                                // boxShadow: "0px 2px 6px -1px rgba(0,0,0,0.1)",
                                padding: 3,
                                background: "#F1F2F4",
                                position: "relative",
                            }, children: [_jsx(Controller, { name: `links.${index}.text`, control: control, render: ({ field, formState }) => {
                                        var _a, _b, _c, _d, _e, _f, _g, _h;
                                        return (_jsx(TextField, { fullWidth: true, size: "medium", error: !!((_b = (_a = formState.errors.links) === null || _a === void 0 ? void 0 : _a[index]) === null || _b === void 0 ? void 0 : _b.text), helperText: ((_e = (_d = (_c = formState.errors.links) === null || _c === void 0 ? void 0 : _c[index]) === null || _d === void 0 ? void 0 : _d.text) === null || _e === void 0 ? void 0 : _e.message) &&
                                                `${t(((_h = (_g = (_f = formState.errors.links) === null || _f === void 0 ? void 0 : _f[index]) === null || _g === void 0 ? void 0 : _g.text) === null || _h === void 0 ? void 0 : _h.message) || "")}`, label: t("createqr-links-text"), ...field }));
                                    } }), _jsx(Controller, { name: `links.${index}.link`, control: control, render: ({ field, formState }) => {
                                        var _a, _b, _c, _d, _e, _f, _g, _h;
                                        return (_jsx(TextField, { fullWidth: true, size: "medium", error: !!((_b = (_a = formState.errors.links) === null || _a === void 0 ? void 0 : _a[index]) === null || _b === void 0 ? void 0 : _b.link), helperText: ((_e = (_d = (_c = formState.errors.links) === null || _c === void 0 ? void 0 : _c[index]) === null || _d === void 0 ? void 0 : _d.link) === null || _e === void 0 ? void 0 : _e.message) &&
                                                `${t(((_h = (_g = (_f = formState.errors.links) === null || _f === void 0 ? void 0 : _f[index]) === null || _g === void 0 ? void 0 : _g.link) === null || _h === void 0 ? void 0 : _h.message) || "")}`, label: t("createqr-links-url"), ...field }));
                                    } })] }, item.id), _jsx(IconButton, { sx: {
                                position: "absolute",
                                top: "0",
                                right: "0",
                                transform: "translate(50%, -50%)",
                            }, color: "error", onClick: () => remove(index), children: _jsx(Cancel, {}) })] }, index));
            }), _jsx(Button, { onClick: () => {
                    append({ text: "", link: "" });
                }, variant: "contained", disableElevation: true, startIcon: _jsx(Add, {}), size: "large", children: t("createqr-links-add") })] }));
};
export default Links;
