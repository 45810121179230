import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowForward, Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, Link, OutlinedInput, Stack, Typography, } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios, { isAxiosError } from "axios";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import loginPath from "../../../img/scanner/login/login.svg";
import Base from "../Base";
import ScannerLoadingButton from "../components/common/ScannerLoadingButton";
import useAuth from "../hooks/useAuth";
const validationSchema = z.object({
    email: z.string().email("login-email-invalid"),
    password: z.string().min(4, "login-password-min"),
});
const Login = () => {
    const { t } = useTranslation();
    const { signin } = useAuth();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const mutation = useMutation({
        mutationFn: (variables) => {
            return axios.post("/api/login", {
                email: variables.email,
                password: variables.password,
            }, {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                },
            });
        },
        onSuccess: (res, variables) => {
            var _a, _b;
            const token = ((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.token) || "";
            const refreshToken = ((_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.refresh_token) || "";
            signin(variables.email, token, refreshToken);
            navigate("/dashboard", { replace: true });
        },
    });
    return (_jsx(Base, { children: _jsxs(Stack, { sx: {
                padding: "0 5%",
                flexFlow: "row-reverse wrap",
                marginTop: "10%",
                alignItems: "center",
                gap: "3rem",
            }, children: [_jsxs(Stack, { sx: { flex: "1 1 400px" }, children: [_jsx(Typography, { variant: "h1", component: "h1", gutterBottom: true, children: t("login-nice-to-see-you") }), _jsx(Typography, { sx: { textAlign: "justify", mb: 2 }, children: t("login-nice-to-see-you-desc") }), _jsxs(FormContainer, { resolver: zodResolver(validationSchema), defaultValues: {
                                email: "",
                                password: "",
                            }, onSuccess: (data) => {
                                mutation.mutate(data, {
                                    onError: (error) => {
                                        var _a;
                                        if (isAxiosError(error)) {
                                            setErrMsg((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.error);
                                        }
                                    },
                                });
                            }, children: [_jsx(TextFieldElement, { fullWidth: true, parseError: (err) => (err.message ? t(err.message) : ""), name: "email", label: t("login-email"), required: true, sx: { mb: 1 } }), _jsx(Controller, { name: "password", render: ({ field, formState }) => {
                                        var _a;
                                        return (_jsxs(FormControl, { fullWidth: true, children: [_jsxs(InputLabel, { htmlFor: "password", error: !!formState.errors.password, children: [t("login-password"), "*"] }), _jsx(OutlinedInput, { ...field, id: "password", type: showPassword ? "text" : "password", error: !!formState.errors.password, endAdornment: _jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { "aria-label": "toggle password visibility", onClick: handleClickShowPassword, onMouseDown: handleMouseDownPassword, edge: "end", children: showPassword ? _jsx(VisibilityOff, {}) : _jsx(Visibility, {}) }) }), label: t("login-password") }), _jsx(FormHelperText, { error: !!formState.errors.password, children: ((_a = formState.errors.password) === null || _a === void 0 ? void 0 : _a.message) &&
                                                        `${t(formState.errors.password.message)}` })] }));
                                    } }), _jsx(Link, { href: "/reset-password", underline: "none", children: t("login-forgot-password") }), _jsx(ScannerLoadingButton, { type: "submit", variant: "contained", endIcon: _jsx(ArrowForward, {}), loading: mutation.isLoading, children: t("login-now-button") })] }), _jsx(FormHelperText, { error: Boolean(errMsg), children: t(errMsg) })] }), _jsx(Box, { sx: {
                        flex: "1 0 50%",
                        textAlign: "center",
                    }, children: _jsx(Box, { sx: { maxWidth: "90%" }, component: "img", alt: "topqr login image", src: loginPath }) })] }) }));
};
export default Login;
