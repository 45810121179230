import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AccountCircle, Dehaze } from "@mui/icons-material";
import { Box, Link, Menu, MenuItem, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import logoPath from "../../../img/scanner/logo.svg";
import useAuth from "../hooks/useAuth";
import ScannerButton from "./common/ScannerButton";
const NoHeaderLinksPage = ["create", "payment"];
const Header = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const { session } = useAuth();
    const loginOrDashboardRoute = session.email ? "/dashboard" : "/login";
    const loginOrDashboardLabel = session.email ? t("dashboard") : t("login");
    return (_jsxs(Stack, { sx: {
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: "#F9FAFB",
        }, children: [_jsxs(Stack, { direction: "row", alignItems: "center", sx: {
                    pointerEvents: pathname.includes("create") ? "none" : "all",
                }, children: [_jsx(Link, { href: "/", children: _jsx(Box, { component: "img", sx: { width: "50px" }, alt: "Topqr Logo", src: logoPath }) }), _jsx(Link, { href: "/", sx: { textDecoration: "none", color: "black" }, children: _jsx(Typography, { variant: "h2", component: "p", sx: {
                                display: {
                                    xs: "block",
                                    fontWeight: "normal",
                                },
                            }, children: "TopQR" }) })] }), !NoHeaderLinksPage.some((seg) => pathname.includes(seg)) && (_jsxs(_Fragment, { children: [_jsxs(Stack, { sx: {
                            display: { xs: "none", sm: "none", md: "flex" },
                            gap: "30px",
                            flexDirection: "row",
                            alignItems: "center",
                        }, children: [_jsx(Link, { href: "/contact", underline: "none", children: _jsx(Typography, { color: "#000", variant: "h6", children: t("contact-us") }) }), _jsx(Link, { href: loginOrDashboardRoute, underline: "none", children: _jsx(ScannerButton, { startIcon: _jsx(AccountCircle, {}), sx: { backgroundColor: "#000", color: "#fff" }, children: loginOrDashboardLabel }) })] }), _jsxs(Stack, { direction: "row", gap: "10px", sx: { display: { xs: "block", sm: "block", md: "none" } }, children: [_jsx(ScannerButton, { sx: {
                                    paddingLeft: 1,
                                    paddingRight: 1,
                                    minWidth: "48px",
                                    maxHeight: "40px",
                                }, id: "scanner-positioned-button", "aria-controls": open ? "scanner-positioned-menu" : undefined, "aria-haspopup": "true", "aria-expanded": open ? "true" : undefined, onClick: handleClick, children: _jsx(Dehaze, { fontSize: "medium" }) }), _jsxs(Menu, { id: "scanner-positioned-menu", "aria-labelledby": "scanner-positioned-button", anchorEl: anchorEl, open: open, onClose: handleClose, anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "right",
                                }, transformOrigin: {
                                    vertical: "top",
                                    horizontal: "right",
                                }, children: [_jsx(Link, { href: "/contact", underline: "none", children: _jsx(MenuItem, { children: _jsx(Typography, { children: t("contact-us") }) }) }), _jsx(Link, { href: loginOrDashboardRoute, underline: "none", children: _jsx(MenuItem, { children: _jsx(Typography, { children: loginOrDashboardLabel }) }) })] })] })] }))] }));
};
export default Header;
