import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { zodResolver } from "@hookform/resolvers/zod";
import { Checkbox, FormControlLabel, FormHelperText, MenuItem, Select, Stack, TextField, Typography, } from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useCreateQR } from "../../context/CreateQRProvider";
import { focusInput } from "../../helpers";
import InputLabelComponent from "../common/InputLabelComponent";
const Wifi = () => {
    const { t } = useTranslation();
    const { setContentForm, currentForm, qrType } = useCreateQR();
    const form = useForm({
        resolver: zodResolver(z.object({
            "wifi-name": z.string().min(1, "required"),
            "wifi-password": z.string().min(1, "required"),
            "wifi-security": z.string().min(1, "required"),
            "wifi-hidden-network": z.boolean(),
        })),
        defaultValues: currentForm && qrType === "wifi"
            ? currentForm.getValues()
            : {
                "wifi-name": "",
                "wifi-password": "",
                "wifi-security": "",
                "wifi-hidden-network": false,
            },
    });
    useEffect(() => {
        focusInput("wifi-name");
        setContentForm(form);
    }, []);
    return (_jsxs(Stack, { children: [_jsx(Typography, { variant: "body1", mb: 2, children: t("create-qr-wifi-description") }), _jsx(Controller, { control: form.control, name: "wifi-name", render: ({ field, formState }) => {
                    var _a;
                    return (_jsx(TextField, { ...field, label: t("createqr-wifi-name"), error: !!formState.errors["wifi-name"], size: "medium", helperText: ((_a = formState.errors["wifi-name"]) === null || _a === void 0 ? void 0 : _a.message) &&
                            `${t(formState.errors["wifi-name"].message)}`, style: { marginBottom: "10px" } }));
                } }), _jsx(Controller, { control: form.control, name: "wifi-password", render: ({ field, formState }) => {
                    var _a;
                    return (_jsx(TextField, { ...field, label: t("createqr-wifi-password"), error: !!formState.errors["wifi-password"], size: "medium", helperText: ((_a = formState.errors["wifi-password"]) === null || _a === void 0 ? void 0 : _a.message) &&
                            `${t(formState.errors["wifi-password"].message)}`, style: { marginBottom: "10px" } }));
                } }), _jsx(Controller, { name: "wifi-security", control: form.control, render: ({ field, formState }) => {
                    var _a;
                    return (_jsxs(_Fragment, { children: [_jsx(InputLabelComponent, { htmlFor: field.name, label: t("createqr-wifi-security"), error: !!formState.errors["wifi-security"] }), _jsxs(Select, { ...field, label: t("createqr-wifi-security"), sx: { maxWidth: "400px" }, error: !!formState.errors["wifi-security"], children: [_jsx(MenuItem, { value: "nopass", children: _jsx("em", { children: t("createqr-wifi-security-none") }) }), _jsx(MenuItem, { value: t("createqr-wifi-security-wep"), children: t("createqr-wifi-security-wep") }), _jsx(MenuItem, { value: t("createqr-wifi-security-wpa"), children: t("createqr-wifi-security-wpa") }), _jsx(MenuItem, { value: t("createqr-wifi-security-wpa2"), children: t("createqr-wifi-security-wpa2") }), _jsx(MenuItem, { value: t("createqr-wifi-security-wpa3"), children: t("createqr-wifi-security-wpa3") })] }), _jsx(FormHelperText, { id: "component-error-text", error: !!formState.errors["wifi-security"], children: (_a = formState.errors["wifi-security"]) === null || _a === void 0 ? void 0 : _a.message })] }));
                } }), _jsx(Controller, { name: "wifi-hidden-network", control: form.control, render: ({ field }) => (_jsx(FormControlLabel, { label: t("createqr-wifi-hidden-network"), control: _jsx(Checkbox, { ...field }) })) })] }));
};
export default Wifi;
