import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { QueryStats } from "@mui/icons-material";
import { Box, FormControl, MenuItem, Select, Stack, TextField, Typography, styled, } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import "dayjs/locale/fr";
import "dayjs/locale/it";
import { getName, registerLocale } from "i18n-iso-countries";
import enCountries from "i18n-iso-countries/langs/en.json";
import frCountries from "i18n-iso-countries/langs/fr.json";
import itCountries from "i18n-iso-countries/langs/it.json";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PieChart } from "react-minimal-pie-chart";
import { useSearchParams } from "react-router-dom";
import ScannerBackdrop from "../../components/common/ScannerBackdrop";
import ScannerButton from "../../components/common/ScannerButton";
import { getToken } from "../../context/AuthProvider";
registerLocale(enCountries);
registerLocale(frCountries);
registerLocale(itCountries);
const StyledTextField = styled(TextField) `
  .MuiInputBase-root {
    background-color: #fff;
  }
`;
const defaultLabelStyle = {
    fontSize: "5px",
};
const generateHexColor = () => {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
};
// there are 195 countries, so generate color for each of theme
const chartColor = Array.from({ length: 195 }, () => generateHexColor());
const Analyze = () => {
    var _a, _b, _c, _d, _e, _f;
    const { t, i18n } = useTranslation();
    const [selectedQR, setSelectedQR] = useState("all");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const qrID = searchParams.get("qrID");
    const query = useQuery(["dashboardCodes"], async () => {
        return await axios.get("/api/dashboard-codes", {
            withCredentials: true,
            headers: { Authorization: "Bearer " + getToken() },
        });
    });
    const { refetch, data: analyzeData } = useQuery({
        queryKey: ["dashboardAnalyze"],
        queryFn: () => {
            let id = qrID && qrID.length > 0 ? qrID : selectedQR;
            if (id === "all") {
                id = null;
            }
            return axios.get("/api/dashboard-analyze", {
                params: {
                    id: id,
                    startDate: startDate === null || startDate === void 0 ? void 0 : startDate.format("DD/MM/YYYY"),
                    endDate: endDate === null || endDate === void 0 ? void 0 : endDate.format("DD/MM/YYYY"),
                },
                withCredentials: true,
                headers: { Authorization: "Bearer " + getToken() },
            });
        },
    });
    const handleChange = (event) => {
        if (qrID && qrID.length > 0) {
            searchParams.delete("qrID");
            setSearchParams(searchParams);
        }
        setSelectedQR(event.target.value);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        refetch();
    };
    const countryData = [];
    const countries = (_b = (_a = analyzeData === null || analyzeData === void 0 ? void 0 : analyzeData.data) === null || _a === void 0 ? void 0 : _a.country) !== null && _b !== void 0 ? _b : [];
    let i = 0;
    for (const label in countries) {
        const data = {
            title: getName(label, i18n.language),
            value: countries[label],
            color: chartColor[chartColor.length - (i + 1)],
        };
        countryData.push(data);
        i++;
    }
    const osData = [];
    const oss = (_d = (_c = analyzeData === null || analyzeData === void 0 ? void 0 : analyzeData.data) === null || _c === void 0 ? void 0 : _c.os) !== null && _d !== void 0 ? _d : [];
    i = 0;
    for (const label in oss) {
        const data = {
            title: label,
            value: oss[label],
            color: chartColor[chartColor.length - (i + 1)],
        };
        osData.push(data);
        i++;
    }
    return (_jsxs(Box, { sx: { padding: "1rem" }, children: [_jsx(ScannerBackdrop, { open: query.isLoading }), _jsx(Typography, { children: t("dashboard-analyze-qr-codes") }), _jsx(Stack, { sx: {
                    flexDirection: { xs: "column", sm: "row" },
                    gap: "2rem",
                    margin: "2rem auto",
                }, children: _jsx(Box, { component: "form", onSubmit: handleSubmit, noValidate: true, autoComplete: "off", sx: {
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: "2rem",
                    }, children: _jsxs(Stack, { sx: {
                            rowGap: "0.5rem",
                            columnGap: "10px",
                            flexDirection: { xs: "column", sm: "row" },
                            width: "100%",
                        }, children: [_jsx(FormControl, { sx: { width: { xs: "100%", sm: "30%" } }, children: _jsxs(Select, { labelId: "qrcode-select-label", id: "qrcode-select", value: qrID && qrID.length > 0 ? qrID : selectedQR, label: "Age", onChange: handleChange, size: "small", sx: { backgroundColor: "#fff" }, children: [_jsx(MenuItem, { value: "all", selected: true, children: t("dashboard-analyze-all-qr") }), (_f = (_e = query.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.map((qr) => {
                                            return (_jsx(MenuItem, { value: qr.id, children: qr.name }, qr.id));
                                        })] }) }), _jsx(LocalizationProvider, { dateAdapter: AdapterDayjs, adapterLocale: i18n.language, children: _jsx(DatePicker, { inputFormat: "DD/MM/YYYY", label: t("dashboard-analyze-select-start-date"), value: startDate, onChange: (newValue) => {
                                        setStartDate(newValue);
                                    }, renderInput: (params) => (_jsx(StyledTextField, { ...params, id: "date", size: "small", helperText: null })) }) }), _jsx(LocalizationProvider, { dateAdapter: AdapterDayjs, adapterLocale: i18n.language, children: _jsx(DatePicker, { label: t("dashboard-analyze-select-end-date"), inputFormat: "DD/MM/YYYY", value: endDate, onChange: (newValue) => {
                                        if (startDate && endDate) {
                                            if (startDate < endDate) {
                                                setEndDate(newValue);
                                                return;
                                            }
                                        }
                                        setEndDate(newValue);
                                    }, renderInput: (params) => (_jsx(StyledTextField, { ...params, id: "date", size: "small", helperText: null })) }) }), _jsx(ScannerButton, { type: "submit", size: "small", children: t("dashboard-analyze-search") })] }) }) }), _jsxs(Stack, { sx: {
                    flexDirection: { xs: "column", sm: "row" },
                    margin: "2rem auto",
                    padding: "1rem",
                    backgroundColor: "#fff",
                    borderStyle: "solid",
                    borderRadius: "10px",
                    borderColor: "lightgray",
                    justifyContent: "space-evenly",
                }, children: [_jsx(Box, { children: _jsxs(Typography, { variant: "h4", children: [_jsx(QueryStats, {}), t("dashboard-analyze-total-scans"), " ", analyzeData === null || analyzeData === void 0 ? void 0 : analyzeData.data.countScan] }) }), _jsx(Box, { children: _jsxs(Typography, { variant: "h4", children: [_jsx(QueryStats, {}), t("dashboard-analyze-total-unique-scans"), " ", analyzeData === null || analyzeData === void 0 ? void 0 : analyzeData.data.countUniqueScan] }) })] }), _jsxs(Stack, { sx: {
                    flexDirection: { xs: "column", sm: "row" },
                    justifyContent: "space-around",
                    gap: "2rem",
                    marginBottom: "3rem",
                }, children: [_jsxs(Box, { children: [_jsx(Typography, { children: t("dashboard-analyze-scans-by-country") }), _jsx(PieChart, { data: countryData, label: ({ dataEntry }) => dataEntry.title + " (" + dataEntry.value + ")", labelStyle: {
                                    ...defaultLabelStyle,
                                }, lineWidth: 15, paddingAngle: 5 })] }), _jsxs(Box, { children: [_jsx(Typography, { children: t("dashboard-analyze-scans-by-os") }), _jsx(PieChart, { data: osData, label: ({ dataEntry }) => dataEntry.title + " (" + dataEntry.value + ")", labelStyle: {
                                    ...defaultLabelStyle,
                                }, lineWidth: 15, paddingAngle: 5 })] })] })] }));
};
export default Analyze;
