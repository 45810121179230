import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Star } from "@mui/icons-material";
import { Box, Stack, styled } from "@mui/material";
import Typography from "@mui/material/Typography";
const YellowStar = styled(Star) `
  color: #ffc53d;
  font-size: 1.1rem;
`;
export default function Review({ imgsrc, author, title, desc }) {
    return (_jsx(Stack, { sx: {
            maxWidth: "270px",
            boxShadow: "2px 5px 10px 4px rgba(0, 0, 0, 0.03)",
            borderRadius: 2,
            background: "#FFF",
            p: 1,
        }, children: _jsxs(Stack, { sx: {
                marginX: "1.2rem",
                marginY: "0.7rem",
            }, children: [_jsx(Box, { marginBottom: "10px", children: _jsx(Typography, { variant: "h5", children: title }) }), _jsx(Typography, { variant: "body2", sx: { fontSize: 15 }, gutterBottom: true, children: desc }), _jsxs(Stack, { sx: { flexDirection: "row", columnGap: "1rem", marginTop: "10px" }, children: [_jsx(Box, { component: "img", width: "48px", height: "48px", src: imgsrc }), _jsxs(Stack, { children: [_jsx(Typography, { variant: "subtitle1", children: author }), _jsxs(Box, { children: [_jsx(YellowStar, {}), _jsx(YellowStar, {}), _jsx(YellowStar, {}), _jsx(YellowStar, {}), _jsx(YellowStar, {})] })] })] })] }) }));
}
