import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Price from "../components/Price";
import Satisfied from "../components/Satisfied";
import { AnonymousSteps } from "../constants";
import { useCreateQR } from "../context/CreateQRProvider";
import useAuth from "../hooks/useAuth";
import CreateMenu from "./Create/CreateQRMenu";
import CreateStepper from "./Create/CreateQRStepper";
const Create = ({ children }) => {
    const { session } = useAuth();
    const { resetQRCodeLayout } = useCreateQR();
    const steps = !session.email ? AnonymousSteps : null;
    useEffect(() => {
        return () => {
            resetQRCodeLayout();
        };
    }, []);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const activeStep = (() => {
        const path = window.location.pathname;
        if (path.includes("payment")) {
            return 1;
        }
        return 0;
    })();
    return (_jsxs(_Fragment, { children: [_jsx(Header, {}), steps && (_jsx(CreateStepper, { activeStep: activeStep, steps: steps, isMobile: isMobile })), _jsxs(Box, { sx: {
                    px: {
                        xs: 2,
                        sm: 4,
                    },
                    pt: 4,
                }, children: [_jsx(CreateMenu, { activeStep: activeStep }), _jsx(Grid, { container: true, spacing: 4, children: children ? children : _jsx(Outlet, {}) })] }), activeStep === 1 && (_jsxs(_Fragment, { children: [_jsx(Satisfied, {}), _jsx(Price, {})] })), _jsx(Footer, {})] }));
};
export default Create;
