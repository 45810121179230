import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AccountBox, Logout, QrCode, QueryStats } from "@mui/icons-material";
import { Box, Tab, Tabs, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, matchPath, useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import useAuth from "../hooks/useAuth";
import Account from "./Dashboard/Account";
import Analyze from "./Dashboard/Analyze";
import QRTab from "./Dashboard/QRTab";
function TabPanel(props) {
    const { children, page, current, ...other } = props;
    return (_jsx(Box, { sx: {
            width: "100%",
            borderStyle: "solid",
            borderRadius: "10px",
            borderColor: "lightgray",
            backgroundColor: "background.paper",
            margin: { xs: "1rem auto" },
        }, role: "tabpanel", hidden: page !== current, id: `vertical-tabpanel-${current}`, "aria-labelledby": `vertical-tab-${current}`, ...other, children: page === current && children }));
}
function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}
function useRouteMatch(patterns) {
    const { pathname } = useLocation();
    for (let i = 0; i < patterns.length; i += 1) {
        const pattern = patterns[i];
        const possibleMatch = matchPath(pattern, pathname);
        if (possibleMatch !== null) {
            return possibleMatch;
        }
    }
    return null;
}
const Dashboard = () => {
    var _a;
    const { t } = useTranslation();
    const { signout } = useAuth();
    const sm = useMediaQuery((theme) => theme.breakpoints.up("sm"));
    const routeMatch = useRouteMatch([
        "/dashboard/qrcodes",
        "/dashboard/analyze",
        "/dashboard/account",
    ]);
    let currentTab = (_a = routeMatch === null || routeMatch === void 0 ? void 0 : routeMatch.pattern) === null || _a === void 0 ? void 0 : _a.path;
    if (currentTab === undefined) {
        currentTab = "/dashboard/qrcodes";
    }
    return (_jsxs(_Fragment, { children: [_jsx(Header, {}), _jsxs(Box, { sx: {
                    flexGrow: 1,
                    display: { sm: "flex" },
                    gap: "1rem",
                    padding: "0 1rem",
                    maxWidth: "1100px",
                    marginX: "auto",
                    marginTop: "3rem",
                    marginBottom: "12.5rem",
                }, children: [_jsxs(Tabs, { value: currentTab, orientation: sm ? "vertical" : "horizontal", variant: "scrollable", sx: {
                            borderRight: { xs: 0, sm: 1 },
                            borderColor: "divider",
                        }, children: [_jsx(Tab, { icon: _jsx(QrCode, {}), iconPosition: "start", label: sm ? t("dashboard-qr-code") : "", value: "/dashboard/qrcodes", to: "qrcodes", component: Link, sx: { whiteSpace: "nowrap" } }), _jsx(Tab, { icon: _jsx(QueryStats, {}), iconPosition: "start", label: sm ? t("dashboard-analyze") : "", value: "/dashboard/analyze", to: "analyze", component: Link, sx: { whiteSpace: "nowrap" } }), _jsx(Tab, { icon: _jsx(AccountBox, {}), iconPosition: "start", label: sm ? t("dashboard-my-account") : "", value: "/dashboard/account", to: "account", component: Link, sx: { whiteSpace: "nowrap" } }), _jsx(Tab, { icon: _jsx(Logout, {}), iconPosition: "start", label: sm ? t("dashboard-logout") : "", value: "/dashboard/logout", to: "account", component: Link, onClick: () => signout(), sx: { whiteSpace: "nowrap" } })] }), _jsx(TabPanel, { page: currentTab, current: "/dashboard/qrcodes", children: _jsx(QRTab, {}) }), _jsx(TabPanel, { page: currentTab, current: "/dashboard/analyze", children: _jsx(Analyze, {}) }), _jsx(TabPanel, { page: currentTab, current: "/dashboard/account", children: _jsx(Account, {}) })] }), _jsx(Footer, {})] }));
};
export default Dashboard;
