import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { zodResolver } from "@hookform/resolvers/zod";
import { AddAPhoto } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, FormHelperText, TextField, Typography, styled, } from "@mui/material";
import { Stack } from "@mui/system";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useCreateQR } from "../../context/CreateQRProvider";
import { URLRegexValidator, focusInput } from "../../helpers";
const StyledBox = styled(Box)({
    flex: "1 1 auto",
});
const VCard = () => {
    const { t } = useTranslation();
    const { setContentForm, currentForm, qrType } = useCreateQR();
    const form = useForm({
        resolver: zodResolver(z.object({
            "vcard-name": z.string().min(1, "required"),
            "vcard-surname": z.string().min(1, "required"),
            "vcard-phone": z.string(),
            "vcard-email": z.string().email("createqr-email-required"),
            "vcard-organization": z.string(),
            "vcard-occupation": z.string(),
            "vcard-country": z.string().optional(),
            "vcard-city": z.string().optional(),
            "vcard-website": z
                .string()
                .regex(URLRegexValidator, "createqr-website-url-required"),
            "file-url": z.string().optional(),
            "vcard-summary": z.string().optional(),
        })),
        defaultValues: currentForm && qrType === "vcard"
            ? currentForm.getValues()
            : {
                "vcard-name": "",
                "vcard-surname": "",
                "vcard-phone": "",
                "vcard-email": "",
                "vcard-organization": "",
                "vcard-occupation": "",
                "vcard-country": "",
                "vcard-city": "",
                "vcard-website": "",
                "file-url": "",
                "file-name": "",
                "vcard-summary": "",
            },
    });
    useEffect(() => {
        setContentForm(form);
        focusInput("vcard-name");
    }, []);
    const mutation = useMutation({
        mutationFn: (data) => {
            return axios.post("/api/code/file", data);
        },
        onSuccess: (data) => {
            form.setValue("file-url", data.data.fileS3Url);
        },
    });
    const handleFileUpload = (e) => {
        var _a, _b;
        const file = (_b = (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0];
        if (file) {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("type", "image");
            mutation.mutate(formData, {
                onSuccess: () => {
                    form.setValue("file-name", file.name);
                },
            });
        }
    };
    useEffect(() => {
        focusInput("vcard-name");
        setContentForm(form);
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "body1", mb: 2, children: t("create-qr-vcard-description") }), _jsxs(Stack, { sx: {
                    flexFlow: "row wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                }, children: [_jsx(Controller, { control: form.control, name: "vcard-name", render: ({ field, formState }) => {
                            var _a;
                            return (_jsx(StyledBox, { children: _jsx(TextField, { fullWidth: true, ...field, type: "text", size: "medium", label: `${t("createqr-vcard-name")}*`, error: !!formState.errors["vcard-name"], helperText: ((_a = formState.errors["vcard-name"]) === null || _a === void 0 ? void 0 : _a.message) &&
                                        `${t(formState.errors["vcard-name"].message)}` }) }));
                        } }), _jsx(Controller, { control: form.control, name: "vcard-surname", render: ({ field, formState }) => {
                            var _a;
                            return (_jsx(StyledBox, { children: _jsx(TextField, { fullWidth: true, ...field, type: "text", size: "medium", label: `${t("createqr-vcard-surname")}*`, error: !!formState.errors["vcard-surname"], helperText: ((_a = formState.errors["vcard-surname"]) === null || _a === void 0 ? void 0 : _a.message) &&
                                        `${t(formState.errors["vcard-surname"].message)}` }) }));
                        } }), _jsx(Controller, { control: form.control, name: "vcard-phone", render: ({ field, formState }) => {
                            var _a;
                            return (_jsx(StyledBox, { children: _jsx(TextField, { ...field, fullWidth: true, type: "text", size: "medium", label: t("createqr-vcard-phone"), error: !!formState.errors["vcard-phone"], helperText: ((_a = formState.errors["vcard-phone"]) === null || _a === void 0 ? void 0 : _a.message) &&
                                        `${t(formState.errors["vcard-phone"].message)}` }) }));
                        } }), _jsx(Controller, { control: form.control, name: "vcard-email", render: ({ field, formState }) => {
                            var _a;
                            return (_jsx(StyledBox, { children: _jsx(TextField, { ...field, fullWidth: true, type: "text", size: "medium", label: `${t("createqr-vcard-email")}*`, error: !!formState.errors["vcard-email"], helperText: ((_a = formState.errors["vcard-email"]) === null || _a === void 0 ? void 0 : _a.message) &&
                                        `${t(formState.errors["vcard-email"].message)}` }) }));
                        } }), _jsx(Controller, { control: form.control, name: "vcard-organization", render: ({ field, formState }) => {
                            var _a;
                            return (_jsx(StyledBox, { children: _jsx(TextField, { ...field, fullWidth: true, type: "text", size: "medium", label: t("createqr-vcard-organization"), error: !!formState.errors["vcard-organization"], helperText: ((_a = formState.errors["vcard-organization"]) === null || _a === void 0 ? void 0 : _a.message) &&
                                        `${t(formState.errors["vcard-organization"].message)}` }) }));
                        } }), _jsx(Controller, { control: form.control, name: "vcard-occupation", render: ({ field, formState }) => {
                            var _a;
                            return (_jsx(StyledBox, { children: _jsx(TextField, { ...field, fullWidth: true, type: "text", size: "medium", label: t("createqr-vcard-occupation"), error: !!formState.errors["vcard-occupation"], helperText: ((_a = formState.errors["vcard-occupation"]) === null || _a === void 0 ? void 0 : _a.message) &&
                                        `${t(formState.errors["vcard-occupation"].message)}` }) }));
                        } }), _jsx(Controller, { control: form.control, name: "vcard-country", render: ({ field, formState }) => {
                            var _a;
                            return (_jsx(StyledBox, { children: _jsx(TextField, { ...field, fullWidth: true, type: "text", size: "medium", label: t("createqr-vcard-country"), error: !!formState.errors["vcard-country"], helperText: ((_a = formState.errors["vcard-country"]) === null || _a === void 0 ? void 0 : _a.message) &&
                                        `${t(formState.errors["vcard-country"].message)}` }) }));
                        } }), _jsx(Controller, { control: form.control, name: "vcard-city", render: ({ field, formState }) => {
                            var _a;
                            return (_jsx(StyledBox, { children: _jsx(TextField, { ...field, fullWidth: true, type: "text", size: "medium", label: t("createqr-vcard-city"), error: !!formState.errors["vcard-city"], helperText: ((_a = formState.errors["vcard-city"]) === null || _a === void 0 ? void 0 : _a.message) &&
                                        `${t(formState.errors["vcard-city"].message)}` }) }));
                        } }), _jsx(Controller, { control: form.control, name: "vcard-website", render: ({ field, formState }) => {
                            var _a;
                            return (_jsx(StyledBox, { children: _jsx(TextField, { ...field, fullWidth: true, type: "text", size: "medium", label: `${t("createqr-vcard-website")}*`, error: !!formState.errors["vcard-website"], helperText: ((_a = formState.errors["vcard-website"]) === null || _a === void 0 ? void 0 : _a.message) &&
                                        `${t(formState.errors["vcard-website"].message)}` }) }));
                        } })] }), _jsx(Controller, { control: form.control, name: "vcard-summary", render: ({ field, formState }) => {
                    var _a;
                    return (_jsx(StyledBox, { children: _jsx(TextField, { sx: { mt: 1 }, ...field, type: "text", size: "medium", multiline: true, rows: 4, fullWidth: true, label: t("createqr-vcard-summary"), error: !!formState.errors["vcard-summary"], helperText: ((_a = formState.errors["vcard-summary"]) === null || _a === void 0 ? void 0 : _a.message) &&
                                `${t(formState.errors["vcard-summary"].message)}` }) }));
                } }), _jsxs(StyledBox, { sx: { paddingTop: "12px" }, children: [_jsx(LoadingButton, { startIcon: _jsx(AddAPhoto, {}), component: "label", variant: "outlined", size: "large", fullWidth: true, loading: mutation.isLoading, children: _jsxs("span", { children: [t("createqr-vcard-picture"), _jsx("input", { hidden: true, accept: "image/*", type: "file", onChange: handleFileUpload })] }) }), _jsx(FormHelperText, { children: form.getValues()["file-name"] &&
                            form.getValues()["file-name"] + t("createqr-uploaded") })] })] }));
};
export default VCard;
