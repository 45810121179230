import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, SvgIcon } from "@mui/material";
import BodyCirclePath from "./QRCodeSVGPaths/BodyCircle";
import BodyCushionPath from "./QRCodeSVGPaths/BodyCushion";
import BodyHeavyRoundedPath from "./QRCodeSVGPaths/BodyHeavyRounded";
import BodySquarePath from "./QRCodeSVGPaths/BodySquare";
import InnerEyeCirclePath from "./QRCodeSVGPaths/InnerEyeCircle";
import InnerEyeCushionPath from "./QRCodeSVGPaths/InnerEyeCushion";
import InnerEyeHeavyRoundedPath from "./QRCodeSVGPaths/InnerEyeHeavyRounded";
import InnerEyeSquarePath from "./QRCodeSVGPaths/InnerEyeSquare";
import OuterEyeCirclePath from "./QRCodeSVGPaths/OuterEyeCircle";
import OuterEyeCushionPath from "./QRCodeSVGPaths/OuterEyeCushion";
import OuterEyeHeavyRoundedPath from "./QRCodeSVGPaths/OuterEyeHeavyRounded";
import OuterEyeSquarePath from "./QRCodeSVGPaths/OuterEyeSquare";
export default function QRCode({ qrStyle }) {
    const Body = (() => {
        switch (qrStyle.styleContent) {
            case "default":
                return BodySquarePath;
            case "circle":
                return BodyCirclePath;
            case "heavyround":
                return BodyHeavyRoundedPath;
            case "classic":
                return BodyCushionPath;
        }
    })();
    const OuterEye = (() => {
        switch (qrStyle.styleOuterEyes) {
            case "default":
                return OuterEyeSquarePath;
            case "circle":
                return OuterEyeCirclePath;
            case "heavyround":
                return OuterEyeHeavyRoundedPath;
            case "cushion":
                return OuterEyeCushionPath;
        }
    })();
    const InnerEye = (() => {
        switch (qrStyle.styleInnerEyes) {
            case "default":
                return InnerEyeSquarePath;
            case "circle":
                return InnerEyeCirclePath;
            case "heavyround":
                return InnerEyeHeavyRoundedPath;
            case "cushion":
                return InnerEyeCushionPath;
        }
    })();
    const SocialIcon = (() => {
        if (!qrStyle.iconUrl)
            return _jsx(_Fragment, {});
        return (_jsx("img", { alt: "Social icon", src: qrStyle.iconUrl, style: {
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                margin: "auto",
                width: "25%",
                height: "25%",
            } }));
    })();
    return (_jsxs(Box, { sx: {
            aspectRatio: "1/1",
            position: "relative",
        }, children: [_jsxs(SvgIcon, { sx: { width: "100%", height: "100%" }, viewBox: "0 0 250 250", children: [_jsx("g", { fill: qrStyle.contentColor, children: _jsx(Body, {}) }), _jsxs("g", { fill: qrStyle.outerColor, children: [_jsx(OuterEye, {}), _jsx(OuterEye, { transform: "translate(180 0)" }), _jsx(OuterEye, { transform: "translate(0 180)" })] }), _jsxs("g", { fill: qrStyle.outerColor, children: [_jsx(InnerEye, { transform: "translate(20 20)" }), _jsx(InnerEye, { transform: "translate(200 20)" }), _jsx(InnerEye, { transform: "translate(20 200)" })] })] }), SocialIcon] }));
}
