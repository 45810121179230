import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DeleteForever, UploadFile } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack, useRadioGroup, } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ColorPicker from "../../components/common/ColorPicker";
import { PanelTitle } from "../../components/common/Panel";
import ScannerButton from "../../components/common/ScannerButton";
import { useCreateQR } from "../../context/CreateQRProvider";
import { BodyCircle, BodyCushion, BodyHeavyRounded, BodySquare, InnerEyeCircle, InnerEyeCushion, InnerEyeHeavyRounded, InnerEyeSquare, OuterEyeCircle, OuterEyeCushion, OuterEyeHeavyRounded, OuterEyeSquare, } from "./Icons";
const radioGroupStyle = {
    display: "flex",
    flexFlow: "row wrap",
    gap: { xs: "1rem", sm: "2rem", md: "3rem" },
    padding: "10px 0 10px 0",
    justifyContent: "center",
    alignItems: "center",
    mb: 3,
    color: "#000",
};
const baseStackStyle = {
    flexDirection: "row",
    justifyContent: "center",
    gap: 1,
    marginBottom: "1rem",
};
const MyFormControlLabel = ({ value, icon }) => {
    const radioGroup = useRadioGroup();
    let checked = false;
    if (radioGroup) {
        checked = radioGroup.value === value;
    }
    const formIcon = icon
        ? {
            ...icon,
            ...{ props: { sx: { fontSize: "5em" }, ...icon.props } },
        }
        : null;
    return (_jsx(FormControlLabel, { value: value, control: _jsx(Radio, { icon: formIcon !== null && formIcon !== void 0 ? formIcon : _jsx(Radio, {}), checkedIcon: formIcon !== null && formIcon !== void 0 ? formIcon : _jsx(Radio, {}) }), label: "", sx: {
            border: "2px solid",
            borderRadius: "12px",
            borderColor: checked ? "#2F54EB" : "#D0D4D9",
            boxShadow: checked ? "0 0 10px rgba(47, 84, 235, 0.4)" : "none",
            backgroundColor: "#FFF",
            marginLeft: "2.5px",
            marginRight: "2.5px",
            transition: "all 0.3s ease",
        } }));
};
const socialIcons = [
    "apple_store",
    "facebook",
    "play_store",
    "instagram",
    "linkedin",
    "pinterest",
    "vimeo",
    "whatsapp",
    "wifi",
    "youtube",
];
const getDefaultIconUrl = (icon) => {
    return `https://tqr-codes.s3.eu-west-3.amazonaws.com/defaulticons/${icon}.svg`;
};
const Style = () => {
    const { t } = useTranslation();
    const { qrStyle, setQRStyle } = useCreateQR();
    const handleContentStyleChange = (e) => {
        setQRStyle({ styleContent: e.target.value });
    };
    const handleOuterEyesChange = (e) => {
        setQRStyle({ styleOuterEyes: e.target.value });
    };
    const handleInnerEyesChange = (e) => {
        setQRStyle({ styleInnerEyes: e.target.value });
    };
    const handleContentColorChange = (color) => {
        setQRStyle({ contentColor: color });
    };
    const handleOuterColorChange = (color) => {
        setQRStyle({ outerColor: color });
    };
    const handleIconChange = (e) => {
        setQRStyle({ iconUrl: e.target.value });
    };
    const removeLogo = () => {
        setQRStyle({ iconUrl: "" });
    };
    const mutation = useMutation({
        mutationFn: (data) => {
            return axios.post("/api/code/file", data);
        },
        onSuccess: (data) => {
            var _a;
            setQRStyle({ iconUrl: (_a = data.data) === null || _a === void 0 ? void 0 : _a.fileS3Url });
        },
    });
    const onFileChange = (e) => {
        var _a, _b;
        const file = (_b = (_a = e.target) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0];
        if (file) {
            const formData = new FormData();
            formData.append("type", "image");
            formData.append("file", file);
            mutation.mutate(formData);
        }
        if (e.target) {
            e.target.value = "";
        }
    };
    return (_jsxs(FormControl, { fullWidth: true, children: [_jsx(PanelTitle, { text: t("createqr-style-code") }), _jsxs(RadioGroup, { "aria-labelledby": "content-style-radio-buttons-group-label", name: "styleContent", sx: radioGroupStyle, value: qrStyle.styleContent, onChange: handleContentStyleChange, children: [_jsx(MyFormControlLabel, { value: "circle", icon: _jsx(BodyCircle, { viewBox: "60 150 70 70", fill: qrStyle.contentColor }) }), _jsx(MyFormControlLabel, { value: "default", icon: _jsx(BodySquare, { viewBox: "60 150 70 70", fill: qrStyle.contentColor }) }), _jsx(MyFormControlLabel, { value: "heavyround", icon: _jsx(BodyHeavyRounded, { viewBox: "60 150 70 70", fill: qrStyle.contentColor }) }), _jsx(MyFormControlLabel, { value: "classic", icon: _jsx(BodyCushion, { viewBox: "60 150 70 70", fill: qrStyle.contentColor }) })] }), _jsxs(RadioGroup, { "aria-labelledby": "radio-buttons-group-label", name: "styleOuterEyes", sx: radioGroupStyle, value: qrStyle.styleOuterEyes, onChange: handleOuterEyesChange, children: [_jsx(MyFormControlLabel, { value: "circle", icon: _jsx(OuterEyeCircle, { fill: qrStyle.outerColor, viewBox: "-30 -30 130 130" }) }), _jsx(MyFormControlLabel, { value: "default", icon: _jsx(OuterEyeSquare, { fill: qrStyle.outerColor, viewBox: "-30 -30 130 130" }) }), _jsx(MyFormControlLabel, { value: "heavyround", icon: _jsx(OuterEyeHeavyRounded, { fill: qrStyle.outerColor, viewBox: "-30 -30 130 130" }) }), _jsx(MyFormControlLabel, { value: "cushion", icon: _jsx(OuterEyeCushion, { fill: qrStyle.outerColor, viewBox: "-30 -30 130 130" }) })] }), _jsxs(RadioGroup, { "aria-labelledby": "radio-buttons-group-label", name: "styleInnerEyes", sx: {
                    ...radioGroupStyle,
                    mb: 6,
                }, value: qrStyle.styleInnerEyes, onChange: handleInnerEyesChange, children: [_jsx(MyFormControlLabel, { value: "circle", icon: _jsx(InnerEyeCircle, { viewBox: "0 0 70 70", fill: qrStyle.outerColor }) }), _jsx(MyFormControlLabel, { value: "default", icon: _jsx(InnerEyeSquare, { viewBox: "0 0 70 70", fill: qrStyle.outerColor }) }), _jsx(MyFormControlLabel, { value: "heavyround", icon: _jsx(InnerEyeHeavyRounded, { viewBox: "0 0 70 70", fill: qrStyle.outerColor }) }), _jsx(MyFormControlLabel, { value: "cushion", icon: _jsx(InnerEyeCushion, { viewBox: "0 0 70 70", fill: qrStyle.outerColor }) })] }), _jsx(Stack, { sx: {
                    flexDirection: "row",
                    gap: "2rem",
                    position: "relative",
                    zIndex: 10,
                    justifyContent: "center",
                    margin: "0 auto 1rem",
                }, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, sm: 6, children: _jsx(ColorPicker, { name: "contentColor", label: t("createqr-style-content-color"), color: qrStyle.contentColor, onChange: handleContentColorChange, sx: { left: "4%" } }) }), _jsx(Grid, { item: true, xs: 12, sm: 6, children: _jsx(ColorPicker, { name: "outerColor", label: t("createqr-style-outer-color"), color: qrStyle.outerColor, onChange: handleOuterColorChange, sx: { left: "55%" } }) })] }) }), _jsxs(Stack, { sx: {
                    padding: "1rem",
                    background: "white",
                    borderRadius: "10px",
                }, alignItems: "center", children: [_jsx(PanelTitle, { text: t("createqr-style-logo"), variant: "h4" }), _jsx(Stack, { sx: {
                            ...baseStackStyle,
                            maxWidth: 500,
                        }, children: _jsx(RadioGroup, { name: "iconUrl", "aria-labelledby": "radio-buttons-group-label", value: qrStyle.iconUrl, onChange: handleIconChange, sx: {
                                ...radioGroupStyle,
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 1,
                                mb: 0,
                            }, children: socialIcons.map((iconName) => (_jsx(MyFormControlLabel, { value: getDefaultIconUrl(iconName), icon: _jsx("img", { width: "50", height: "50", alt: iconName, src: getDefaultIconUrl(iconName) }) }, iconName))) }) }), _jsx(Stack, { sx: baseStackStyle, children: !qrStyle.iconUrl ? (_jsx(LoadingButton, { variant: "contained", disableElevation: true, component: "label", loading: mutation.isLoading, startIcon: _jsx(UploadFile, {}), sx: {
                                textTransform: "capitalize",
                                borderRadius: "8px",
                                padding: "10px 30px",
                                textAlign: "center",
                            }, children: _jsxs("span", { children: [t("createqr-logo-upload"), _jsx("input", { hidden: true, accept: "application/image", type: "file", onChange: onFileChange })] }) })) : (_jsx(ScannerButton, { startIcon: _jsx(DeleteForever, {}), variant: "outlined", color: "warning", onClick: removeLogo, sx: { margin: 0 }, children: t("createqr-logo-remove") })) })] })] }));
};
export default Style;
