import { jsx as _jsx } from "react/jsx-runtime";
import { InputLabel } from "@mui/material";
function InputLabelComponent({ htmlFor, label, error, sx }) {
    return (_jsx(InputLabel, { sx: {
            margin: "5px 0 0 0",
            fontSize: "12px",
            fontWeight: "500",
            color: "#0E0F11",
            ...sx,
        }, htmlFor: htmlFor, error: error, children: label }));
}
export default InputLabelComponent;
