import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { zodResolver } from "@hookform/resolvers/zod";
import { AccountCircle, Check, CheckCircle, LockRounded, } from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DownloadIcon from "@mui/icons-material/Download";
import SecurityIcon from "@mui/icons-material/Security";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Checkbox, FormControlLabel, FormHelperText, Grid, InputAdornment, Link, Stack, } from "@mui/material";
import Typography from "@mui/material/Typography";
import { InputMask } from "@react-input/mask";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import valid from "card-validator";
import { defer } from "lodash-es";
import { forwardRef, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";
import PaymentCBIcon from "../../../../img/scanner/create/cb.svg";
import PaymentMastercardIcon from "../../../../img/scanner/create/mastercard.svg";
import PaymentSSLIcon from "../../../../img/scanner/create/ssl.svg";
import PaymentVisaIcon from "../../../../img/scanner/create/visa.svg";
import { TextInput } from "../../components/Form";
import ScannerBackdrop from "../../components/common/ScannerBackdrop";
import { useCreateQR } from "../../context/CreateQRProvider";
import { focusInput } from "../../helpers";
import useAuth from "../../hooks/useAuth";
import QRCode from "./QRCode";
const cardStyles = {
    padding: "2rem 3rem 2rem",
    backgroundColor: "#FFFFFF",
    border: "1px solid #dedede",
    borderRadius: "8px",
    boxShadow: "0px 2px 6px -1px rgba(0,0,0,0.1)",
    position: "relative",
};
const CardNumberMaskFieldComponent = forwardRef(function CardNumberMaskField(otherProps, inputRef) {
    return (_jsx(InputMask, { ...otherProps, ref: inputRef, mask: "____ ____ ____ ____", replacement: { _: /\d/ } }));
});
const CardSecurityMaskFieldComponent = forwardRef(function CardSecurityMaskField(otherProps, inputRef) {
    return (_jsx(InputMask, { ...otherProps, ref: inputRef, mask: "___", replacement: { _: /\d/ } }));
});
const CardExpireMaskFieldComponent = forwardRef(function CardExpireMaskField(otherProps, inputRef) {
    return (_jsx(InputMask, { ...otherProps, ref: inputRef, mask: "__/__", replacement: { _: /\d/ } }));
});
const schema = z.object({
    cardHolder: z.string().refine((val) => valid.cardholderName(val).isValid, {
        message: "createqr-download-cardHolder-invalid",
    }),
    // cardNumber min is 19 because of the spaces
    cardNumber: z.string().min(19, "createqr-download-cardNumber-invalid"),
    cardExpire: z
        .string()
        .refine((value) => valid.expirationDate(value).isValid, {
        message: "createqr-download-cardExpire-invalid",
    }),
    cardSecurity: z
        .string()
        .max(3)
        .refine((value) => valid.cvv(value).isValid, {
        message: "createqr-download-cardSecurity-invalid",
    }),
    terms: z.literal(true, {
        errorMap: () => ({ message: "createqr-terms-required" }),
    }),
});
function getCardScheme(number) {
    // visa
    let re = new RegExp("^4");
    if (number.match(re) != null) {
        return "visa";
    }
    // Mastercard
    re = new RegExp("^5[1-5]+");
    if (number.match(re) != null) {
        return "mastercard";
    }
    // cb
    re = new RegExp("^5[6-9]|^60+");
    if (number.match(re) != null) {
        return "cb";
    }
    return "";
}
const Download = ({ ccData }) => {
    var _a;
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { signin } = useAuth();
    const { qrStyle } = useCreateQR();
    const [identifierParams, setIdentifierParams] = useSearchParams();
    const [cardType, setCardType] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const identifier = identifierParams.get("identifier");
    const { setQRStyle } = useCreateQR();
    const [loading, setLoading] = useState(false);
    const mutation = useMutation({
        mutationFn: (variables) => {
            return axios.post("/api/payment", {
                formData: variables,
            }, {
                headers: { "Content-Type": "application/json" },
            });
        },
        onSuccess: (data) => {
            if (data.data.token) {
                if (__ENV__.isProduction && !__ENV__.isStaging) {
                    window.dataLayer.push({
                        event: "payment_successful",
                        email: localStorage.getItem("onboardingEmail"),
                    });
                }
                signin(localStorage.getItem("onboardingEmail") || "", data.data.token, data.data.refresh_token);
                navigate("/dashboard", { replace: true });
            }
            else if (data.data.redirect) {
                window.location.replace(data.data.redirect);
            }
            else if (data.data.jwt) {
                setLoading(true);
                const st = window.SecureTrading({
                    jwt: data.data.jwt,
                    livestatus: data.data.livestatus,
                    submitOnError: true,
                    submitCallback: (dataCallback) => {
                        setLoading(false);
                    },
                });
                st.Components({ startOnLoad: true });
            }
        },
        onError: (error) => {
            var _a;
            if (error instanceof AxiosError) {
                setErrMsg((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.error);
            }
        },
    });
    const form = useForm({
        resolver: zodResolver(schema),
        mode: "onChange",
        defaultValues: {
            cardHolder: "",
            cardNumber: "",
            cardExpire: "",
            cardSecurity: "",
            terms: false,
        },
    });
    const { handleSubmit, control, setValue, getFieldState, formState, setError, } = form;
    const onSubmit = (data) => {
        data.cardNumber = data.cardNumber.replace(/ /g, "");
        mutation.mutate(data);
    };
    const DEV_autofillCardData = () => {
        const cardHolder = "John Doe";
        const cardNumber = "4111 1111 1111 1151";
        const cardExpire = "12/25";
        const cardSecurity = "123";
        setValue("cardHolder", cardHolder);
        setValue("cardNumber", cardNumber);
        setValue("cardExpire", cardExpire);
        setValue("cardSecurity", cardSecurity);
    };
    useEffect(() => {
        if (!ccData)
            return;
        setValue("cardHolder", ccData.cardHolder);
        setValue("cardNumber", ccData.cardNumber);
        setValue("cardExpire", ccData.cardExpire);
        setValue("cardSecurity", ccData.cardSecurity);
        if (ccData.cvvError) {
            setError("cardSecurity", { type: "custom" });
            focusInput("cardSecurity");
        }
    }, [ccData]);
    useEffect(() => {
        focusInput("cardHolder");
    }, []);
    const focusNextIfValid = (e, name, next) => {
        defer(() => {
            const isFocused = document.activeElement === e.target;
            const shouldFocusNext = !getFieldState(name).invalid;
            if (isFocused && shouldFocusNext) {
                focusInput(next);
            }
        });
    };
    const query = useQuery({
        queryKey: ["paymentIdentifier"],
        queryFn: async () => {
            return await axios.get(`/api/check-payment/${identifier}/`);
        },
        enabled: !!identifier,
        retry: false,
        onSuccess: (data) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
            // console.log("Check payment res", data);
            const token = ((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.token) || "";
            const refreshToken = ((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.refresh_token) || "";
            if (token) {
                if (__ENV__.isProduction && !__ENV__.isStaging) {
                    window.dataLayer.push({
                        event: "payment_successful",
                        email: (_c = data === null || data === void 0 ? void 0 : data.data) === null || _c === void 0 ? void 0 : _c.email,
                    });
                }
                signin((_d = data === null || data === void 0 ? void 0 : data.data) === null || _d === void 0 ? void 0 : _d.email, token, refreshToken);
                navigate("/dashboard/sms", { replace: true });
                return;
            }
            setErrMsg((_e = data === null || data === void 0 ? void 0 : data.data) === null || _e === void 0 ? void 0 : _e.error);
            setValue("cardHolder", (_f = data === null || data === void 0 ? void 0 : data.data) === null || _f === void 0 ? void 0 : _f.cardHolder);
            setValue("cardNumber", (_g = data === null || data === void 0 ? void 0 : data.data) === null || _g === void 0 ? void 0 : _g.cardNumber);
            setValue("cardExpire", (_h = data === null || data === void 0 ? void 0 : data.data) === null || _h === void 0 ? void 0 : _h.cardExpiry);
            setValue("cardSecurity", (_j = data === null || data === void 0 ? void 0 : data.data) === null || _j === void 0 ? void 0 : _j.cardSecurity);
            setQRStyle({
                styleContent: (_k = data === null || data === void 0 ? void 0 : data.data) === null || _k === void 0 ? void 0 : _k.code.styleContent,
                styleOuterEyes: (_l = data === null || data === void 0 ? void 0 : data.data) === null || _l === void 0 ? void 0 : _l.code.styleOuterEyes,
                styleInnerEyes: (_m = data === null || data === void 0 ? void 0 : data.data) === null || _m === void 0 ? void 0 : _m.code.styleInnerEyes,
                contentColor: (_o = data === null || data === void 0 ? void 0 : data.data) === null || _o === void 0 ? void 0 : _o.code.contentColor,
                outerColor: (_p = data === null || data === void 0 ? void 0 : data.data) === null || _p === void 0 ? void 0 : _p.code.outerColor,
                iconUrl: (_q = data === null || data === void 0 ? void 0 : data.data) === null || _q === void 0 ? void 0 : _q.code.iconUrl,
            });
        },
        onError: (error) => {
            var _a;
            if (error instanceof AxiosError) {
                setErrMsg((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.error);
            }
            else {
                setErrMsg("There has been a problem with the payment.");
            }
            identifierParams.delete("identifier");
            setIdentifierParams(identifierParams);
        },
    });
    return (_jsxs(_Fragment, { children: [_jsx(Grid, { item: true, xs: 12, md: 6, children: _jsxs(Stack, { sx: {
                        ...cardStyles,
                        borderColor: "rgb(47, 84, 235)",
                        borderWidth: 2,
                        padding: {
                            md: "2rem 4rem",
                            xs: "1rem 1rem",
                        },
                    }, children: [_jsx(ScannerBackdrop, { open: mutation.isLoading || loading }), _jsxs(Stack, { mb: 2, sx: {
                                mr: {
                                    md: -8,
                                    xs: -2,
                                },
                                ml: {
                                    md: -8,
                                    xs: -2,
                                },
                                background: "linear-gradient(-45deg, #f1f1f1,  white)",
                                p: {
                                    xs: 2,
                                    md: 3,
                                },
                                alignItems: "center",
                            }, children: [_jsxs(Typography, { variant: "h4", display: "flex", alignItems: "center", sx: {
                                        fontSize: {
                                            xs: 12,
                                            md: 16,
                                        },
                                        mt: 1,
                                    }, children: [_jsx(LockRounded, { sx: { mr: 1, mb: 1 } }), t("createqr-secure-payment")] }), _jsx(Stack, { alignItems: "center", children: _jsx(Typography, { variant: "body1", sx: {
                                            fontSize: 18,
                                            fontWeight: 700,
                                        }, children: t("createqr-payment-amount") }) })] }), _jsxs(Stack, { sx: {
                                flexDirection: "row",
                                justifyContent: "center",
                                gap: "5%",
                                mb: 3,
                            }, children: [i18n.language === "fr" && (_jsx(Box, { component: "img", src: PaymentCBIcon, sx: { maxHeight: "1.4rem" } })), _jsx(Box, { component: "img", src: PaymentVisaIcon, sx: { maxHeight: "1.4rem" } }), _jsx(Box, { component: "img", src: PaymentMastercardIcon, sx: { maxHeight: "1.4rem" } }), _jsx(Box, { component: "img", src: PaymentSSLIcon, sx: { maxHeight: "1.4rem" } })] }), _jsx(Box, { id: "st-notification-frame" }), _jsxs("form", { id: "st-form", action: "/api/valid-payment", method: "POST", onSubmit: handleSubmit(onSubmit), children: [_jsxs(Stack, { gap: 1, mb: 1, children: [_jsx(TextInput, { control: control, name: "cardHolder", label: t("createqr-download-cardHolder-placeholder"), formState: formState, fullWidth: true, inputProps: {
                                                inputMode: "text",
                                            }, startAdornment: _jsx(InputAdornment, { position: "start", children: _jsx(AccountCircle, {}) }) }), _jsx(TextInput, { onChangeCb: (e) => {
                                                focusNextIfValid(e, "cardNumber", "cardExpire");
                                                setCardType(getCardScheme(e.target.value));
                                            }, control: control, name: "cardNumber", inputProps: { inputMode: "numeric" }, label: t("createqr-download-cardNumber-placeholder"), inputComponent: CardNumberMaskFieldComponent, formState: formState, startAdornment: cardType ? (_jsx(InputAdornment, { position: "start", children: {
                                                    visa: (_jsx(Box, { component: "img", src: PaymentVisaIcon, sx: { maxHeight: "1rem", maxWidth: "2.5rem" } })),
                                                    mastercard: (_jsx(Box, { component: "img", src: PaymentMastercardIcon, sx: { maxHeight: "1rem", maxWidth: "2.5rem" } })),
                                                    cb: (_jsx(Box, { component: "img", src: PaymentCBIcon, sx: { maxHeight: "1rem", maxWidth: "2.5rem" } })),
                                                }[cardType] })) : (_jsx(InputAdornment, { position: "start", children: _jsx(CreditCardIcon, {}) })), fullWidth: true })] }), _jsxs(Stack, { sx: {
                                        flexDirection: "row",
                                        gap: 1,
                                        mb: 2,
                                    }, children: [_jsx(TextInput, { control: control, name: "cardExpire", inputProps: { inputMode: "numeric" }, label: t("createqr-download-cardExpire-placeholder"), inputComponent: CardExpireMaskFieldComponent, formState: formState, startAdornment: _jsx(InputAdornment, { position: "start", children: _jsx(CalendarMonthIcon, {}) }), onChangeCb: (e) => {
                                                focusNextIfValid(e, "cardExpire", "cardSecurity");
                                                setCardType(getCardScheme(e.target.value));
                                            } }), _jsx(TextInput, { control: control, startAdornment: _jsx(InputAdornment, { position: "start", children: _jsx(LockRounded, {}) }), name: "cardSecurity", inputProps: { inputMode: "numeric" }, label: t("createqr-download-cardSecurity-placeholder"), inputComponent: CardSecurityMaskFieldComponent, formState: formState })] }), _jsx(FormHelperText, { error: Boolean(errMsg), sx: {
                                        pb: 2,
                                        pl: 2,
                                    }, children: errMsg }), _jsx(Controller, { control: form.control, name: "terms", render: ({ field }) => (_jsx(_Fragment, { children: _jsx(FormControlLabel, { ...field, checked: !!field.value, control: _jsx(Checkbox, { sx: {
                                                    position: "relative",
                                                    top: -10,
                                                    left: 5,
                                                }, size: "small" }), sx: {
                                                alignItems: "flex-start",
                                            }, label: _jsx(Box, { sx: {
                                                    ml: 1,
                                                }, children: _jsxs(Typography, { variant: "body2", sx: {
                                                        color: "rgb(33, 38, 47)",
                                                        cursor: "pointer",
                                                        fontFamily: "Montserrat, Arial, Tahoma, Verdana, Helvetica, sans-serif",
                                                        fontSize: "9.625px",
                                                        fontWeight: 400,
                                                        lineHeight: "13.4667px",
                                                        marginBottom: "5px",
                                                        position: "relative",
                                                        textAlign: "justify",
                                                    }, children: [t("createqr-email-terms-accept-first"), _jsx(Link, { href: "/terms", target: "_blank", sx: {
                                                                textDecoration: "none",
                                                                color: "rgb(0, 123, 255)",
                                                            }, children: t("createqr-email-terms-accept-second") }), t("createqr-email-terms-accept-third"), _jsx(Link, { href: "/privacy", target: "_blank", sx: {
                                                                textDecoration: "none",
                                                                color: "rgb(0, 123, 255)",
                                                            }, children: t("createqr-email-terms-accept-fourth") }), t("createqr-email-terms-accept-fifth"), " ", _jsx(Typography, { display: "inline", variant: "body2", sx: {
                                                                textAlign: "left",
                                                                fontSize: 8,
                                                                lineHeight: "11.2px",
                                                            }, children: t("createqr-terms-accept-addendum") })] }) }) }) })) }), _jsx(FormHelperText, { sx: { pl: 1, pb: 1 }, error: !!form.formState.errors.terms, children: ((_a = form.formState.errors.terms) === null || _a === void 0 ? void 0 : _a.message) &&
                                        `${t(form.formState.errors.terms.message)}` }), _jsx(Stack, { textAlign: "right", direction: "row", justifyContent: "center", alignItems: "center", gap: 1, mt: 2, sx: {
                                        background: "linear-gradient(-45deg, #f1f1f1, white)",
                                        p: "1.5rem 3rem",
                                        mr: {
                                            md: -8,
                                            xs: -2,
                                        },
                                        ml: {
                                            md: -8,
                                            xs: -2,
                                        },
                                    }, children: _jsx(LoadingButton, { loading: mutation.isLoading, startIcon: _jsx(LockRounded, {}), type: "submit", variant: "contained", color: "success", size: "large", disableElevation: true, sx: { minWidth: 210 }, children: _jsx("span", { children: t("createqr-confirm-and-download") }) }) }), !__ENV__.isProduction && (_jsx(Button, { variant: "outlined", sx: { width: "100%", marginTop: "1rem" }, onClick: DEV_autofillCardData, children: "DEV - Autofill card data" }))] })] }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsxs(Stack, { direction: "column", height: "100%", children: [_jsxs(Box, { sx: {
                                ...cardStyles,
                                py: 8,
                            }, mb: 2, children: [_jsxs(Stack, { sx: {
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: 1.25,
                                        mb: 4,
                                    }, children: [_jsx(CheckCircle, { color: "success" }), _jsx(Typography, { variant: "h4", sx: {
                                                fontSize: 16,
                                                fontWeight: 400,
                                            }, children: t("payment-sidebar-a-title") })] }), _jsxs(Stack, { direction: "row", justifyContent: "center", alignItems: "center", gap: 2.5, children: [_jsx(Box, { sx: {
                                                width: "100px",
                                            }, children: _jsx(QRCode, { qrStyle: qrStyle }) }), _jsx(Box, { children: _jsxs(Stack, { gap: 1, children: [_jsxs(Stack, { direction: "row", justifyContent: "start", alignItems: "center", gap: 0.5, children: [_jsx(Check, { sx: {
                                                                    color: "success.main",
                                                                    fontSize: "1.5rem",
                                                                    mr: 1,
                                                                } }), _jsx(Typography, { variant: "body2", sx: {
                                                                    fontSize: 16,
                                                                    fontWeight: 400,
                                                                }, children: t("payment-sidebar-a-item-1") })] }), _jsxs(Stack, { direction: "row", justifyContent: "start", alignItems: "center", gap: 0.5, children: [_jsx(Check, { sx: {
                                                                    color: "success.main",
                                                                    fontSize: "1.5rem",
                                                                    mr: 1,
                                                                } }), _jsx(Typography, { variant: "body2", sx: {
                                                                    fontSize: 16,
                                                                    fontWeight: 400,
                                                                }, children: t("payment-sidebar-a-item-2") })] }), _jsxs(Stack, { direction: "row", justifyContent: "start", alignItems: "center", gap: 0.5, children: [_jsx(Check, { sx: {
                                                                    color: "success.main",
                                                                    fontSize: "1.5rem",
                                                                    mr: 1,
                                                                } }), _jsx(Typography, { variant: "body2", sx: {
                                                                    fontSize: 16,
                                                                    fontWeight: 400,
                                                                }, children: t("payment-sidebar-a-item-3") })] })] }) })] })] }), _jsxs(Box, { sx: {
                                ...cardStyles,
                                boxShadow: "none",
                                backgroundColor: "#F8F8F8",
                                borderColor: "transparent",
                                py: 2,
                                flex: "1 1 auto",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                            }, children: [_jsxs(Stack, { gap: 1, sx: {
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textAlign: "center",
                                        mb: 2,
                                    }, children: [_jsx(DownloadIcon, { color: "success" }), _jsx(Typography, { variant: "h4", sx: {
                                                fontSize: 16,
                                            }, children: t("payment-sidebar-b-title") })] }), _jsxs(Stack, { direction: "row", alignItems: "center", children: [_jsx(VisibilityOffIcon, { sx: {
                                                color: "blue",
                                                fontSize: "1rem",
                                                mr: 1,
                                            } }), _jsx(Typography, { variant: "body2", sx: {
                                                color: "black",
                                                fontSize: 18,
                                            }, children: t("payment-sidebar-b-section-1-title") })] }), _jsx(Typography, { variant: "body2", fontSize: 14, sx: {
                                        li: {
                                            marginBottom: "5px",
                                        },
                                    }, children: _jsxs("ul", { style: {
                                            paddingLeft: 20,
                                        }, children: [_jsx("li", { children: t("payment-sidebar-b-section-1-item-1") }), _jsx("li", { children: t("payment-sidebar-b-section-1-item-2") })] }) }), _jsxs(Stack, { direction: "row", alignItems: "center", children: [_jsx(SecurityIcon, { sx: {
                                                color: "blue",
                                                fontSize: "1rem",
                                                mr: 1,
                                            } }), _jsx(Typography, { variant: "body2", sx: {
                                                color: "black",
                                                fontSize: 18,
                                            }, children: t("payment-sidebar-b-section-2-title") })] }), _jsx(Typography, { variant: "body2", fontSize: 14, sx: {
                                        li: {
                                            marginBottom: "5px",
                                        },
                                    }, children: _jsxs("ul", { style: {
                                            marginBottom: 0,
                                            paddingLeft: 20,
                                        }, children: [_jsx("li", { children: t("payment-sidebar-b-section-2-item-1") }), _jsx("li", { children: t("payment-sidebar-b-section-2-item-2") })] }) })] })] }) })] }));
};
export default Download;
