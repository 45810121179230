var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import theme from "./Theme";
import CookiesBanner from "./components/CookiesBanner";
import PageNotFound from "./components/PageNotFound";
import RequireAuth from "./components/RequireAuth";
import ScrollToTop from "./components/ScrollToTop";
import { AuthProvider } from "./context/AuthProvider";
import { CreateQRPProvider } from "./context/CreateQRProvider";
import "./i18n";
import Contact from "./pages/Contact";
import Cookies from "./pages/Cookies";
import Download from "./pages/Create/Download";
import CreateQRLayout from "./pages/CreateQRLayout";
import CreateQRUI from "./pages/CreateQRUI";
import Dashboard from "./pages/Dashboard";
import DemoQR from "./pages/DemoQR";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Prices from "./pages/Prices";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import ResetPassword from "./pages/ResetPassword";
import Terms from "./pages/Terms";
import Unsubscribe from "./pages/Unsubscribe";
import UpdatePassword from "./pages/UpdatePassword";
const recaptchaSiteKey = (_a = process.env.GOOGLE_RECAPTCHA_SITE_KEY_TOPQR) !== null && _a !== void 0 ? _a : "";
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0,
            cacheTime: 0,
        },
    },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(_jsx(React.StrictMode, { children: _jsx(AuthProvider, { children: _jsx(Box, { sx: { maxWidth: "1400px", margin: "auto" }, children: _jsxs(BrowserRouter, { children: [_jsx(ScrollToTop, {}), _jsx(QueryClientProvider, { client: queryClient, children: _jsx(ThemeProvider, { theme: theme, children: _jsxs(CreateQRPProvider, { children: [_jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(Home, {}) }), _jsxs(Route, { path: "/create", element: _jsx(CreateQRLayout, {}), children: [_jsx(Route, { index: true, element: _jsx(CreateQRUI, {}) }), _jsx(Route, { path: "payment", element: _jsx(Download, {}) })] }), _jsx(Route, { path: "/payment", element: _jsx(CreateQRLayout, { children: _jsx(Download, {}) }) }), _jsx(Route, { path: "/unsubscribe", element: _jsx(Unsubscribe, { recaptchaSiteKey: recaptchaSiteKey }) }), _jsx(Route, { path: "/prices", element: _jsx(Prices, {}) }), _jsx(Route, { path: "/refunds", element: _jsx(RefundPolicy, {}) }), _jsx(Route, { path: "/privacy", element: _jsx(PrivacyPolicy, {}) }), _jsx(Route, { path: "/cookies", element: _jsx(Cookies, {}) }), _jsx(Route, { path: "/terms", element: _jsx(Terms, {}) }), _jsx(Route, { path: "/contact", element: _jsx(Contact, { recaptchaSiteKey: recaptchaSiteKey }) }), _jsx(Route, { path: "/login", element: _jsx(Login, {}) }), _jsx(Route, { path: "/reset-password", element: _jsx(ResetPassword, {}) }), _jsx(Route, { path: "/update-password", element: _jsx(UpdatePassword, {}) }), _jsx(Route, { path: "/demo-qr", element: _jsx(DemoQR, {}) }), _jsx(Route, { element: _jsx(RequireAuth, {}), children: _jsx(Route, { path: "/dashboard/*", element: _jsx(Dashboard, {}) }) }), _jsx(Route, { path: "*", element: _jsx(PageNotFound, {}) })] }), _jsx(CookiesBanner, {})] }) }) })] }) }) }) }));
