import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Link, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import FakeQRImage from "../../../img/scanner/fake-qr.png";
import Footer from "../components/Footer";
import Header from "../components/Header";
const DemoQR = () => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx(Header, {}), _jsxs(Stack, { sx: {
                    padding: "0 5%",
                    marginTop: "10%",
                    alignItems: "center",
                    gap: "3rem",
                    textAlign: "center",
                }, children: [_jsx(Typography, { variant: "h5", maxWidth: "323px", children: t("demoqr-fake-title") }), _jsx(Box, { component: "img", src: FakeQRImage, alt: "fake qr image" }), _jsx(Link, { href: "/", children: _jsx(Button, { sx: { minWidth: "182px" }, variant: "contained", children: t("demoqr-fake-button") }) })] }), _jsx(Footer, {})] }));
};
export default DemoQR;
