import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack } from "@mui/system";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import NextButton from "../../components/common/NextButton";
import { useCreateQR } from "../../context/CreateQRProvider";
import QRCode from "./QRCode";
export default function Sidebar() {
    const { qrStyle, creatingQRCode, handleNextClick } = useCreateQR();
    const { t } = useTranslation();
    useEffect(() => {
        const handler = (e) => {
            if (e.key === "Enter") {
                if (creatingQRCode.status === "idle") {
                    console.log("enter handled");
                    handleNextClick();
                }
            }
        };
        document.addEventListener("keydown", handler);
        return () => {
            document.removeEventListener("keydown", handler);
        };
    }, []);
    return (_jsxs(Box, { sx: { position: "sticky", top: "20px" }, children: [_jsx(Box, { sx: {
                    borderRadius: "12px",
                    padding: "5%",
                    textAlign: "center",
                    mb: 1,
                    position: "relative",
                    zIndex: 1,
                }, children: _jsx(QRCode, { qrStyle: qrStyle }) }), _jsx(Stack, { sx: {
                    gap: 2,
                    px: 2,
                    flexDirection: "row",
                    justifyContent: "center",
                }, children: _jsx(NextButton, {}) })] }));
}
