import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ExpandMore, Palette } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, } from "@mui/material";
import { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Application from "../components/Content/Application";
import Image from "../components/Content/Image";
import Links from "../components/Content/Links";
import PDF from "../components/Content/PDF";
import VCard from "../components/Content/VCard";
import Video from "../components/Content/Video";
import Website from "../components/Content/Website";
import Wifi from "../components/Content/Wifi";
import CreateModal from "../components/CreateModal";
import NextButton from "../components/common/NextButton";
import { Panel } from "../components/common/Panel";
import { useCreateQR } from "../context/CreateQRProvider";
import useAuth from "../hooks/useAuth";
import Sidebar from "./Create/CreateQRSidebar";
import CreateQRStyle from "./Create/CreateQRStyle";
export default function Create() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { qrType, setCreatingQRCodeState, createQRCode, creatingQRCode } = useCreateQR();
    const [styleExpanded, setStyleExpanded] = useState(false);
    const { session } = useAuth();
    useEffect(() => {
        setStyleExpanded(false);
    }, [qrType]);
    useEffect(() => {
        if (creatingQRCode.status === "success") {
            navigate("/payment");
            setCreatingQRCodeState({ status: "idle" });
        }
    }, [creatingQRCode.status]);
    useEffect(() => {
        const handleBeforeUnload = (e) => {
            e.preventDefault();
            e.returnValue =
                "Your QR code details will be lost if leave the page, do you want to continue?";
        };
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);
    const openModal = !session.email &&
        (creatingQRCode.status === "email-cta" ||
            creatingQRCode.status === "error" ||
            creatingQRCode.status === "pending");
    return (_jsxs(_Fragment, { children: [_jsx(Grid, { item: true, xs: 12, sm: 8, children: _jsxs(Box, { children: [_jsx(Panel, { children: (() => {
                                switch (qrType) {
                                    case "website":
                                        return _jsx(Website, {});
                                    case "application":
                                        return _jsx(Application, {});
                                    case "wifi":
                                        return _jsx(Wifi, {});
                                    case "links":
                                        return _jsx(Links, {});
                                    case "pdf":
                                        return _jsx(PDF, {});
                                    case "video":
                                        return _jsx(Video, {});
                                    case "image":
                                        return _jsx(Image, {});
                                    case "vcard":
                                        return _jsx(VCard, {});
                                    default:
                                        return _jsxs("div", { children: [" ", t("createqr-choose-mandatory")] });
                                }
                            })() }), _jsx(Box, { sx: {
                                display: {
                                    sm: "none",
                                    xs: "flex",
                                },
                                justifyContent: "center",
                            }, children: _jsx(NextButton, { sx: {
                                    maxWidth: 300,
                                } }) }), _jsx(Box, { sx: {
                                "> .MuiAccordion-root": {
                                    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                                    margin: "16px 0",
                                    border: "none",
                                },
                                "> .MuiAccordion-root:before": {
                                    display: "none",
                                },
                                borderRadius: "8px",
                            }, children: _jsxs(Accordion, { expanded: styleExpanded, onChange: () => {
                                    setStyleExpanded(!styleExpanded);
                                }, children: [_jsxs(AccordionSummary, { expandIcon: _jsx(ExpandMore, {}), "aria-controls": "panel2a-content", id: "panel2a-header", sx: {
                                            fontFamily: "sans-serif",
                                            fontSize: "1.2rem",
                                            fontWeight: 600,
                                        }, children: [_jsx(Palette, { sx: { mr: 2 } }), t("customize-qr-code")] }), _jsx(AccordionDetails, { children: _jsx(CreateQRStyle, {}) })] }) }), _jsx(Suspense, { children: openModal && (_jsx(CreateModal, { onResolve: ({ email }) => {
                                    localStorage.setItem("onboardingEmail", email);
                                    createQRCode();
                                }, onReject: () => {
                                    setCreatingQRCodeState({ status: "idle" });
                                } })) })] }) }), _jsx(Grid, { item: true, xs: 12, sm: 4, children: _jsx(Sidebar, {}) })] }));
}
